import axios from 'axios'
import jwt_decode from "jwt-decode";


const state ={
  user:'',
  tokenAccess:'',
  status:'',
  nameuser:'',
  loggedIn:false
}
const getters = {
  isAuthenticated: state => !!state.tokenAccess,
  getToken: state => state.tokenAccess,
  user: state => state.user,
  nameuser: state => state.nameuser,
  loggedIn: state => state.loggedIn
 }

const actions ={
    [process.env.VUE_APP_AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {

      axios.post(`${process.env.VUE_APP_URL_AZURE_CITEC}?service=login`,user).then(response => {

        if(!response.data.success){
          commit(process.env.VUE_APP_AUTH_ERROR,'auth fail')
          reject('auth fail')
        }
    
        const  tokenSession = response.data.data
     
        commit(process.env.VUE_APP_AUTH_SUCCESS,{tokenAccess:tokenSession})
        resolve({message:'success'})
      }).catch(err=>{
        commit(process.env.VUE_APP_AUTH_ERROR,err)
        reject(err)
      })
    })
  },
  [process.env.VUE_APP_AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(process.env.VUE_APP_AUTH_LOGOUT);
      resolve({message:'success'});
    });
  },
  [process.env.VUE_APP_AUTH_SUCCESS]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(process.env.VUE_APP_AUTH_SUCCESS);
      resolve();
    });
  },
}

const mutations ={
  [process.env.VUE_APP_AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";

    let decoded = jwt_decode(resp.tokenAccess)

   // console.log('decoed',decoded);
    let user = decoded.data.user

    state.user = user
    state.nameuser = `${user.MnSr_Nombre}  ${user.MnSr_Paterno} ${user.MnSr_Materno}`  
    state.tokenAccess = resp.tokenAccess
    state.loggedIn = true

  },
  [process.env.VUE_APP_AUTH_ERROR]: state => {
    state.status = "error";
    state.tokenAccess = null;
    state.loggedIn = false
  },
  [process.env.VUE_APP_AUTH_LOGOUT]: state => {
    state.user = null;
    state.nameuser = null;
    state.status = null;
    state.tokenAccess = null;
    state.loggedIn = false
  }
}
export default {
  state,
  getters,
  actions,
  mutations
};
