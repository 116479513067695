<template>

  <div class="flex w-full">
    <div class="grid grid-cols-12 gap-1 ">
      <div class="col-span-8 bg-paper-light px-0 py-0 rounded bg-opacity-60">
        <div class="flex justify-between px-2 py-2">
          <span class="text-base-100">Visualización</span>
          <div class="flex">

          </div>
          <div></div>
        </div>

        <div class="flex py-0">
          <!--div class="md:flex items-center justify-center w-full"-->
            <!--div id="player" class="p-0 w-full rounded"-->
                <video :src="videoUrl" width="100%" id="simpleVideo" autoplay controls playsinline webkit-playsinline  poster="../assets/images/poster.png" class="video" data-setup='{"controls": true}'></video>
            <!--/div-->
          <!--/div-->
        </div>
      </div>
      <div class="col-span-4 bg-paper-light p-2 rounded bg-opacity-60">
        <div>
          <span class="text-base-100">
            Jugadas Relevantes - {{nombreJugador}}
          </span>

          <div>
            <div class="rounded border border-neutral-200 bg-white ">
              <h2 class="mb-0" id="tituloPartido1">
                <button
                  class="group relative flex w-full items-center rounded-t-[15px] border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] ]"
                  type="button" data-te-collapse-init data-te-target="#partido1" aria-expanded="false"
                  aria-controls="partido1">
                  {{descripcionPArtido}}
                  <span
                    class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="h-6 w-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </span>
                </button>
              </h2>
              <div id="partido1" class="!visible" data-te-collapse-item data-te-collapse-show
                aria-labelledby="tituloPartido1" data-te-parent="#accordionExample">
                <div class="py-4 px-5">
                  <div class="text-gray-600 p-10">

                    <div class="">
                      <div class="flex text-gray-600">
                        <div class="p-2 w-8 flex-shrink-0"></div>
                        <div class="p-2 w-full">Jugada</div>

                        <div class="p-2 w-12 flex-shrink-0 text-right">⏱</div>
                      </div>

                      <div class="flex border-b border-gray-800 hover:bg-blue-500 cursor-pointer" v-for="(play,rowIndex) in plays" :key="rowIndex" v-on:click="buscarVideo(plays[rowIndex][3])">
                        <div class="p-3 w-8 flex-shrink-0">▶️</div>
                        <div class="p-3 w-full"> {{jugada[plays[rowIndex][4]]}} </div>
                        <div class="p-3 w-12 flex-shrink-0 text-right">{{convertToMMSS(plays[rowIndex][3])}}</div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>


</template>


<script>

import CanchaCitec from "@/componentes/3d/CanchaCitec.js";
import GolstatsServices from '@/services/GolstatsServices'
import WyscoutServices from '@/services/WyscoutServices'
import axios from 'axios';
const matchesTranslate = require("./data/matchesTranslate.json").matchesTranslate;
const playerTranslate = require("./data/playerTranslate.json").playerTranslate;


export default {
  name: "videosJugador",
  data: () => ({
    golstatsServices: undefined,
    wyscoutServices: undefined,
    dataVideo:undefined,
    plays:[],
    playsSeparated:{},
    videoUrl:"",
    descripcionPArtido: "",
    nombreJugador: "",
    jugada:{12:'Gol',
            14:'Tarjeta Amarilla',
            15:'Tarjeta Roja',
            18:'Fouls received',
            12000:'Pases',
            53800:'Alguna Jugada',
            730:'Otra jugada'
    },
  }),
  components: {

  },
  props: {
    idPlayer:{
        type: Number,
    },
    idGame:{
        type: Number,
    },
  },

  methods: {

    async invocaData(){
        let dataAcces = {"mail":"pasaporte@axeleratum.com","pass":"sJJett_DffSv#767466554"}
        await this.golstatsServices.login(dataAcces )
        .then(result=>{
        }).catch(error =>{
            console.log(error);
        })
    },

    async invocaDataJugador(){

        let params = {
        "idPlayer": this.idPlayer,
        "idGame":this.idGame
        }

        await this.golstatsServices.eventosPartidoJugadorSeleccion(params)
        .then(result=>{
            
            this.plays = result.data.data.plays
            
            this.plays.map(play =>{

                //console.log("Entra:");

                if(this.playsSeparated[play[4]] == undefined){
                    this.playsSeparated[play[4]] = [] 
                }

                this.playsSeparated[play[4]].push(play) 
                //console.log("resultado", this.playsSeparated[play[4]]);
                 

                
            })
        }).catch(error =>{
            console.log(error);
        })
    },

    convertirTiempoASegundos(tiempo) {
        // Dividir el tiempo en minutos, segundos y milisegundos
        let [minutos, segundosMilisegundos] = tiempo.split(':');
        let [segundos, milisegundos] = segundosMilisegundos.split('.');

        // Convertir los minutos, segundos y milisegundos a números
        minutos = parseInt(minutos);
        segundos = parseInt(segundos);
        milisegundos = parseInt(milisegundos);

        // Calcular el tiempo total en segundos
        let tiempoEnSegundos = (minutos * 60) + segundos + (milisegundos / 1000);

        return tiempoEnSegundos;
    },

    convertToMMSS(tiempo) {
        const timeArr = tiempo.split(":");
        const minutes = parseInt(timeArr[0]);
        const seconds = parseFloat(timeArr[1]);
        const mm = String(minutes).padStart(2, "0");
        const ss = Math.floor(seconds).toString().padStart(2, "0");
        return `${mm}:${ss}`;
    },


    async invocaDataWy(tiempo){
        let dataAcces = {"mail":"crobles@fmf.mx","pass":"f3s8d_388#djj14md02nb"}
         this.wyscoutServices.login(dataAcces )
        .then(result=>{
            //console.log(result);
        }).catch(error =>{
            console.log(error);
        })
    },


    buscarVideo (tiempo){

        let params = {
        "idPlayer": this.idPlayer,
        "idGame":this.idGame
        }
        let iniTime = 0
        let endTime = 0
        iniTime = this.convertirTiempoASegundos(tiempo)
        endTime = iniTime+10


        //if(Object.keys(matchesTranslate).includes(this.idGame)){
        //  console.log("Existe partido");
        //}


        this.wyscoutServices.getVideoJugada(matchesTranslate[this.idGame].idwyscouts,Math.round(iniTime),Math.round(endTime))
        .then(response => {
          this.results = response.data;
          this.videoUrl = this.results.data.sd.url

          //console.log(this.results.data).sd;
        })
        .catch(error => {
          console.log(error);
        });

    }    

  },


  async mounted() {

      this.golstatsServices = new GolstatsServices(this)
      this.wyscoutServices = new WyscoutServices(this)
      await this.invocaData()
      await this.invocaDataWy()
      this.invocaDataJugador()
      this.descripcionPArtido = matchesTranslate[this.idGame].descripcion
      this.nombreJugador = playerTranslate[this.idPlayer].nombre

  }
}
</script>