<template>
  <div class="containerStats">
    <header class="header">
      <button class="back-button" aria-label="Volver">←</button>
    </header>
    <div>TEST</div>
    <div v-if="!rosterLoaded" class="loading-message">
      <p>Cargando datos del roster...</p>
    </div>

    <div v-else-if="rosterError" class="error-message">
      <p>{{ rosterError }}</p>
    </div>

    <template v-else>
        
      <div class="players-container">
        <!-- Home Team Players -->
        <div class="team-section">
          <Swiper
            :modules="[Navigation]"
            :slides-per-view="1"
            :space-between="10"
            :navigation="true"
            @swiper="onHomeSwiper"
            @slide-change="onHomeSwiperSlideChange"
            class="home-swiper"
          >
            <SwiperSlide v-for="(player, index) in homeTeamPlayers" :key="index">
              <div class="player">
                <div class="player-image">
                  <img :src="getPlayerImage(player.playerID)" :alt="player.playerName">
                </div>
                <div class="player-info-container">
                  <div class="player-color-line left-color"></div>
                  <div class="player-info-content">
                    <div class="player-name">{{ player.playerName }}</div>
                    <div class="player-info"># {{ player.jerseyNumber }}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <!-- Away Team Players -->
        <div class="team-section">
          <Swiper
            :modules="[Navigation]"
            :slides-per-view="1"
            :space-between="10"
            :navigation="true"
            @swiper="onAwaySwiper"
            @slide-change="onAwaySwiperSlideChange"
            class="away-swiper"
          >
            <SwiperSlide v-for="(player, index) in awayTeamPlayers" :key="index">
              <div class="player">
                <div class="player-image">
                  <img :src="getPlayerImage(player.playerID)" :alt="player.playerName">
                </div>
                <div class="player-info-container">
                  <div class="player-color-line right-color"></div>
                  <div class="player-info-content">
                    <div class="player-name">{{ player.playerName }}</div>
                    <div class="player-info"># {{ player.jerseyNumber }}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div class="stats-hexagon">
        <div class="chart-container">
          <canvas ref="radarChart"></canvas>
        </div>
      </div>

      <div class="player-stats">
        <div class="stats-column left-stats">
          <div v-for="stat in homePlayerStats" :key="stat.label" class="stat-row">
            <span class="stat-label-text">{{ stat.label }}</span>
            <div class="stat-bar-container">
              <div class="stat-bar" :style="{ width: `${stat.percentage}%` }"></div>
            </div>
            <span class="stat-value">{{ stat.value }}</span>
          </div>
        </div>
        <div class="stats-column right-stats">
          <div v-for="stat in awayPlayerStats" :key="stat.label" class="stat-row">
            <span class="stat-value">{{ stat.value }}</span>
            <div class="stat-bar-container">
              <div class="stat-bar" :style="{ width: `${stat.percentage}%` }"></div>
            </div>
            <span class="stat-label-text">{{ stat.label }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import Chart from 'chart.js/auto'
import axios from 'axios'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'

export default {
  name: 'PlayerComparisonWidget',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    gameId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const radarChart = ref(null)
    const rosterData = ref(null)
    const homeTeamIndex = ref(0)
    const awayTeamIndex = ref(0)
    const homePlayerStats = ref([])
    const awayPlayerStats = ref([])
    const rosterLoaded = ref(false)
    const rosterError = ref(null)

    const mockRosterData = {
      teams: [
        {
          starter_players: [
            { playerID: '111171', playerName: 'Jugador 1', jerseyNumber: '10' },
            { playerID: '111172', playerName: 'Jugador 2', jerseyNumber: '11' }
          ]
        },
        {
          starter_players: [
            { playerID: '111173', playerName: 'Jugador 3', jerseyNumber: '7' },
            { playerID: '111174', playerName: 'Jugador 4', jerseyNumber: '9' }
          ]
        }
      ]
    }

    const mockPlayerStats = {
      '111171': [
        { label: 'Goles', value: '2', percentage: 60 },
        { label: 'Minutos', value: '90\'', percentage: 90 },
        { label: 'Distancia', value: '10.5 KM', percentage: 70 }
      ],
      '111172': [
        { label: 'Goles', value: '1', percentage: 30 },
        { label: 'Minutos', value: '75\'', percentage: 75 },
        { label: 'Distancia', value: '9.2 KM', percentage: 61 }
      ],
      '111173': [
        { label: 'Goles', value: '3', percentage: 90 },
        { label: 'Minutos', value: '88\'', percentage: 88 },
        { label: 'Distancia', value: '11.3 KM', percentage: 75 }
      ],
      '111174': [
        { label: 'Goles', value: '0', percentage: 0 },
        { label: 'Minutos', value: '45\'', percentage: 45 },
        { label: 'Distancia', value: '5.7 KM', percentage: 38 }
      ]
    }

    const fetchRosterData = async () => {
      try {
        // Simular carga de datos
        await new Promise(resolve => setTimeout(resolve, 1000))
        rosterData.value = mockRosterData
        rosterLoaded.value = true

        // Código comentado para obtener datos reales
        //const response = await axios.get(`http://localhost:7071/api/CitecWidgetStats?game_id=${props.gameId}`)
        const response = await axios.get(`https://iad360.citec.futbol/api/CitecWidgetStats?game_id=${props.gameId}`)

        rosterData.value = response.data
        rosterLoaded.value = true
        
      } catch (error) {
        console.error('Error fetching roster data:', error)
        rosterError.value = "Aún no estan disponibles las alineaciones. Por favor, inténtelo de nuevo más tarde."
      }
    }





    const fetchPlayerStats = async (playerId) => {
      try {
        // Simular carga de datos
        //await new Promise(resolve => setTimeout(resolve, 500))
        //return mockPlayerStats[playerId] || []

        //Código comentado para obtener datos reales
        //const response = await axios.get(`http://localhost:7071/api/CitecWidgetStats?player_id=${playerId}`)
        const response = await axios.get(`https://iad360.citec.futbol/api/CitecWidgetStats?player_id=${playerId}`)
        return response.data || []

      } catch (error) {
        console.error('Error fetching player stats:', error)
        return []
      }
    }

    const homeTeamPlayers = computed(() => {
      return rosterData.value?.teams?.[0]?.starter_players || []
    })

    const awayTeamPlayers = computed(() => {
      return rosterData.value?.teams?.[1]?.starter_players || []
    })

    const getPlayerImage = (playerId) => {
      return `https://s3.amazonaws.com/lmxwebsite/media/wpagephotos/75/1/${playerId}/${playerId}.jpg`
    }

const updateRadarChart = () => {
  if (!radarChart.value) return;

  const chartInstance = radarChart.value.__chartInstance;

  if (chartInstance) {
    chartInstance.data.datasets[0].label = homeTeamPlayers.value[homeTeamIndex.value]?.playerName || '';
    chartInstance.data.datasets[0].data = [85, 70, 90, 80, 75, 88];

    chartInstance.data.datasets[1].label = awayTeamPlayers.value[awayTeamIndex.value]?.playerName || '';
    chartInstance.data.datasets[1].data = [90, 65, 75, 95, 80, 85];

    chartInstance.update();
  } else {
    const ctx = radarChart.value.getContext('2d');
    radarChart.value.__chartInstance = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: ['Attacking', 'Defending', 'Passing', 'Dribbling', 'Physical', 'Speed'],
        datasets: [
          {
            label: homeTeamPlayers.value[homeTeamIndex.value]?.playerName || '',
            data: [85, 70, 90, 80, 75, 88],
            backgroundColor: 'rgba(255, 77, 124, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            pointBackgroundColor: 'rgba(255, 99, 132, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132, 1)'
          },
          {
            label: awayTeamPlayers.value[awayTeamIndex.value]?.playerName || '',
            data: [90, 65, 75, 95, 80, 85],
            backgroundColor: 'rgba(0, 255, 179, 0.2)',
            borderColor: 'rgba(0, 255, 179, 1)',
            pointBackgroundColor: 'rgba(0, 255, 179, 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(0, 255, 179, 1)'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            angleLines: { display: true },
            suggestedMin: 0,
            suggestedMax: 100
          }
        },
        plugins: {
          legend: { position: 'top' }
        }
      }
    });
  }
};


    const onHomeSwiper = (swiper) => {
      console.log('______Home swiper initialized', swiper)
    }

    const onAwaySwiper = (swiper) => {
      console.log('Away swiper initialized', swiper)
    }

    const onHomeSwiperSlideChange = async (swiper) => {

        console.log('***Home swiper slide changed:', swiper.activeIndex)
      homeTeamIndex.value = swiper.activeIndex
      const currentPlayer = homeTeamPlayers.value[homeTeamIndex.value]
      if (currentPlayer) {
        const stats = await fetchPlayerStats(currentPlayer.playerID)
        console.log(stats);
        homePlayerStats.value = stats.length > 0 ? stats : [
          { label: 'Goles', value: '0', percentage: 0 },
          { label: 'Minutos', value: '0\'', percentage: 0 },
          { label: 'Distancia', value: '0 KM', percentage: 0 }
        ]
      }
      updateRadarChart()
    }

    const onAwaySwiperSlideChange = async (swiper) => {

        console.log('***Away swiper slide changed:', swiper.activeIndex)
      awayTeamIndex.value = swiper.activeIndex
      const currentPlayer = awayTeamPlayers.value[awayTeamIndex.value]
      if (currentPlayer) {
        const stats = await fetchPlayerStats(currentPlayer.playerID)
        awayPlayerStats.value = stats.length > 0 ? stats : [
          { label: 'Goles', value: '0', percentage: 0 },
          { label: 'Minutos', value: '0\'', percentage: 0 },
          { label: 'Distancia', value: '0 KM', percentage: 0 }
        ]
      }
      updateRadarChart()
    }

    onMounted(async () => {
      await fetchRosterData()
      if (homeTeamPlayers.value.length > 0) {
        const homeStats = await fetchPlayerStats(homeTeamPlayers.value[0].playerID)

        console.log(homeStats);
        homePlayerStats.value = homeStats.length > 0 ? homeStats : [
          { label: 'Goles', value: '0', percentage: 0 },
          { label: 'Minutos', value: '0\'', percentage: 0 },
          { label: 'Distancia', value: '0 KM', percentage: 0 }
        ]
      }
      if (awayTeamPlayers.value.length > 0) {
        const awayStats = await fetchPlayerStats(awayTeamPlayers.value[0].playerID)
        awayPlayerStats.value = awayStats.length > 0 ? awayStats : [
          { label: 'Goles', value: '0', percentage: 0 },
          { label: 'Minutos', value: '0\'', percentage: 0 },
          { label: 'Distancia', value: '0 KM', percentage: 0 }
        ]
      }
      updateRadarChart()
    })

    return {
      radarChart,
      homeTeamPlayers,
      awayTeamPlayers,
      homePlayerStats,
      awayPlayerStats,
      getPlayerImage,
      onHomeSwiper,
      onAwaySwiper,
      onHomeSwiperSlideChange,
      onAwaySwiperSlideChange,
      Navigation,
      rosterLoaded,
      rosterError
    }
  }
}
</script>

<style scoped>
.containerStats {
  margin: 0 auto;
  padding: 20px;
  background-color: #1a1f2e;
  color: #ffffff;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.back-button {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
}.players-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 20px;
}

.team-section {
  width: 48%;
  height: auto;
}

.home-swiper,
.away-swiper {
  width: 100%;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.player {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.player-image {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  margin: 0 auto 12px;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.player-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.player-info-container {
  position: relative;
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.player-color-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  border-radius: 1.5px;
}

.left-color {
  left: 0;
  background-color: #ff4d7e;
}

.right-color {
  left: 0;
  background-color: #00ffb3;
}

.player-info-content {
  padding-left: 15px;
}

.player-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.player-info {
  font-size: 14px;
  color: #8b92a5;
}

.stats-hexagon {
  position: relative;
  width: 100%;
  height: 300px;
  margin: 32px 0;
}

.chart-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(38, 43, 58, 0.3);
  border-radius: 12px;
}

.player-stats {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.stats-column {
  flex: 1;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.stat-label-text,
.stat-value {
  font-size: 14px;
  min-width: 70px;
}

.stat-label-text {
  color: #8b92a5;
}

.stat-value {
  font-weight: 600;
}

.stat-bar-container {
  flex: 1;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 15px;
  border-radius: 2px;
  overflow: hidden;
}

.stat-bar {
  height: 100%;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.left-stats .stat-bar {
  background-color: #ff4d7e;
}

.right-stats .stat-bar {
  background-color: #00ffb3;
}

:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

:deep(.swiper-button-next:after),
:deep(.swiper-button-prev:after) {
  font-size: 14px;
}

:deep(.swiper-button-disabled) {
  opacity: 0.35;
}

.loading-message,
.error-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #ffffff;
}

.error-message {
  color: #ff4d7e;
}
</style>

