
import axios from 'axios'
import * as BABYLON from 'babylonjs'
import 'babylonjs-loaders'
import * as GUI from 'babylonjs-gui'
import createAtlas from 'babylon-atlas'
import Lines from './Lines'
// import *  as MeshWriter from '../../assets/js/meshwriter.min.js'

BABYLON.GUI = GUI

// BABYLON.MeshWriter = MeshWriter

export default class CanchaCitec {
  constructor (vueInstance, componenteId, staticCamera,isPorteria) {
    this.vueInstance = vueInstance
    this.componenteId = componenteId
    this.localPlayeres3d = {}
    this.visitaPlayeres3d = {}
    this.staticCamera = staticCamera
    this.xTotal = 20
    this.xInicio = -10
    this.zTotal = 13.3
    this.zInicio = -6.6
    this.local = false
    this.meshHud = {}
    this.meshHudNarration = {}
    this.atlas = {}
    this.num = 0
    this.advancedTexture = {}
    this.rend = this.rend.bind(this)
    this.startLoop = this.startLoop.bind(this)
    this.stopLoop = this.stopLoop.bind(this)
    this.startStopLoop = this.startStopLoop.bind(this)
    this.rectangleContainerNarration = {}
    this.narrationObjectsWasInit = false
    this.narracionTextDetalle = {}
    this.narracionTextMinute = {}
    this.lines = new Lines(BABYLON)
    this.temporaldrawedLines = []
    this.temporaldrawedLinesIdentifier = {}
    this.allPlayers = []
    this.allPosition = []
    this.equipoLocal = {}
    this.equipoVisita = {}
    this.typeAlineation = 0
    this.initialPositions = {}
    this.idLocal = 0
    this.idVisita = 0
    this.golstatsNewCoordinates = { zInicio: -11.5, zTotal: 23, xInicio: -6.6, xTotal: 13.3 }
    this.colorsClub = []
    this.emitter1 = {}
    this.particleSystem2 = {}
    this.points = []
    this.simpleColors = ['#FDFEFE','#5DADE2','#A569BD','#C0392B','#D5D8DC','#CCCCFF',"#e51c23", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#5677fc", "#03a9f4", "#00bcd4", "#009688", "#259b24", "#8bc34a", "#afb42b", "#ff9800", "#ff5722", "#795548", "#607d8b"]
    this.isPorteria = isPorteria

    if(!isPorteria){
      this.init()
    }else{
      this.initPorteria() 
    } 

  }

  init () {
    
    let myself = this



    BABYLON.DefaultLoadingScreen.prototype.displayLoadingUI = function () {
    // console.log('loading');
    };

    BABYLON.DefaultLoadingScreen.prototype.hideLoadingUI = function(){
        //document.getElementById("customLoadingScreenDiv").style.display = "none";
    //   console.log("scene is now loaded");
    }



    let canvas = document.getElementById(this.componenteId)

    this.vueInstance.$options.engine = new BABYLON.Engine(canvas, true, {
      doNotHandleContextLost: true
    })

    this.vueInstance.$options.scene = new BABYLON.Scene(this.vueInstance.$options.engine)

      //this.vueInstance.$options.scene.debugLayer.show()

    this.vueInstance.$options.camera = new BABYLON.ArcRotateCamera('camera1', 0, 0, 0, new BABYLON.Vector3(0, 1, 0), this.vueInstance.$options.scene)

    this.vueInstance.$options.camera.setPosition(new BABYLON.Vector3(15, 10, 0))

    this.vueInstance.$options.camera.upperBetaLimit = Math.PI / 2
    this.vueInstance.$options.camera.lowerBetaLimit = 0.1

    this.vueInstance.$options.camera.lowerRadiusLimit = 10
    this.vueInstance.$options.camera.upperRadiusLimit = 22
    this.vueInstance.$options.scene.clearColor = BABYLON.Color3.Black()


    // let dome = new BABYLON.PhotoDome(
    //   'testDome',
    //   '/models/Texturas/bg_negro.jpg',
    //   {
    //     resolution: 32,
    //     size: 100
    //   },
    //   this.vueInstance.$options.scene)

    let background = new BABYLON.Layer('back', process.env.VUE_APP_BASE_PATH + 'models/Texturas/bg.jpg', this.vueInstance.$options.scene)
    background.isBackground = true
    background.texture.level = 3

    // var skybox = BABYLON.Mesh.CreateBox('skyBox', 100.0, this.vueInstance.$options.scene);
    // var skyboxMaterial = new BABYLON.StandardMaterial('skyBox', this.vueInstance.$options.scene)
    // skyboxMaterial.backFaceCulling = false
    // skyboxMaterial.diffuseTexture = new BABYLON.Texture('/models/Texturas/bg_negro.jpg', this.vueInstance.$options.scene)
    // // skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
    // skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0)
    // skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0)
    // skyboxMaterial.disableLighting = true
    // skybox.material = skyboxMaterial
    // skybox.infiniteDistance = true

    if(!this.staticCamera){
      this.vueInstance.$options.camera.attachControl(canvas, true)
    }

    

    canvas.style.width = '100%'
    canvas.style.height = '100%'

    var light = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 1, 0), this.vueInstance.$options.scene)
    light.intensity = 0.7

    this.advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI')
    this.advancedTexture.idealWidth =600

   // this.initHud()

    this.vueInstance.$options.engine.doNotHandleContextLost = true
    this.vueInstance.$options.engine.enableOfflineSupport = false
    this.vueInstance.$options.engine.setHardwareScalingLevel(0.5) 
    // this.vueInstance.$options.scene.clearCachedVertexData();
    this.vueInstance.$options.scene.cleanCachedTextureBuffer()

    this.vueInstance.$options.scene.onPointerObservable.add(this.startStopLoop, BABYLON.PointerEventTypes.POINTERWHEEL)

    this.vueInstance.$options.scene.onPointerDown = function () {
      myself.startLoop()
    }



    // this.vueInstance.$options.scene.onPointerPick = function () {
    //   myself.startStopLoop(500)
    // }


    this.vueInstance.$options.scene.onPointerUp = function () {
      myself.stopLoop()
    }

    window.addEventListener('resize', function () {      
      myself.vueInstance.$options.engine.resize()
    })

    this.vueInstance.$options.engine.stopRenderLoop()
    this.rend()

    let index = 0

    this.vueInstance.$options.scene.registerBeforeRender(function () {
      

      if (myself.points.length > 0 && index < myself.points.length) {

        let position = myself.points[index]

        if (!isNaN(position.x)) {
          myself.emitter1.position.x = position.x
          myself.emitter1.position.y = .1
          myself.emitter1.position.z = position.y
          index++
        }
        
      } else if(index > 1){
        myself.points = []
        index = 0
        myself.stopLoop()
      }

    })

  }



  initPorteria () {
    
    let myself = this

    this.xInicio =-1.6
    this.xTotal = 3.2
    
    this.zInicio = .1
    this.zTotal = .6

    BABYLON.DefaultLoadingScreen.prototype.displayLoadingUI = function () {
    // console.log('loading');
    };

    BABYLON.DefaultLoadingScreen.prototype.hideLoadingUI = function(){
        //document.getElementById("customLoadingScreenDiv").style.display = "none";
    //   console.log("scene is now loaded");
    }



    let canvas = document.getElementById(this.componenteId)

    this.vueInstance.$options.engine = new BABYLON.Engine(canvas, true, {
      doNotHandleContextLost: true
    })

    this.vueInstance.$options.scene = new BABYLON.Scene(this.vueInstance.$options.engine)

    //  this.vueInstance.$options.scene.debugLayer.show()

    this.vueInstance.$options.camera = new BABYLON.ArcRotateCamera('camera1', 0, 0, 0, new BABYLON.Vector3(0, .4, 10), this.vueInstance.$options.scene)

    this.vueInstance.$options.camera.setPosition(new BABYLON.Vector3(1, 1, 0))

    this.vueInstance.$options.camera.upperBetaLimit = Math.PI / 2
    this.vueInstance.$options.camera.lowerBetaLimit = 0.1

    this.vueInstance.$options.camera.lowerRadiusLimit = 1
    this.vueInstance.$options.camera.upperRadiusLimit = 1.95
    this.vueInstance.$options.scene.clearColor = BABYLON.Color3.Black()


    // let dome = new BABYLON.PhotoDome(
    //   'testDome',
    //   '/models/Texturas/bg_negro.jpg',
    //   {
    //     resolution: 32,
    //     size: 100
    //   },
    //   this.vueInstance.$options.scene)

    let background = new BABYLON.Layer('back', process.env.VUE_APP_BASE_PATH + 'models/Texturas/bg.jpg', this.vueInstance.$options.scene)
    background.isBackground = true
    background.texture.level = 3

    // var skybox = BABYLON.Mesh.CreateBox('skyBox', 100.0, this.vueInstance.$options.scene);
    // var skyboxMaterial = new BABYLON.StandardMaterial('skyBox', this.vueInstance.$options.scene)
    // skyboxMaterial.backFaceCulling = false
    // skyboxMaterial.diffuseTexture = new BABYLON.Texture('/models/Texturas/bg_negro.jpg', this.vueInstance.$options.scene)
    // // skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
    // skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0)
    // skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0)
    // skyboxMaterial.disableLighting = true
    // skybox.material = skyboxMaterial
    // skybox.infiniteDistance = true

    if(!this.staticCamera){
      this.vueInstance.$options.camera.attachControl(canvas, true)
    }

    

    canvas.style.width = '100%'
    canvas.style.height = '100%'

    var light = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 1, 0), this.vueInstance.$options.scene)
    light.intensity = 0.7

    this.advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI')
    this.advancedTexture.idealWidth =600

   // this.initHud()

    this.vueInstance.$options.engine.doNotHandleContextLost = true
    this.vueInstance.$options.engine.enableOfflineSupport = false
    this.vueInstance.$options.engine.setHardwareScalingLevel(0.5) 
    // this.vueInstance.$options.scene.clearCachedVertexData();
    this.vueInstance.$options.scene.cleanCachedTextureBuffer()

    this.vueInstance.$options.scene.onPointerObservable.add(this.startStopLoop, BABYLON.PointerEventTypes.POINTERWHEEL)

    this.vueInstance.$options.scene.onPointerDown = function () {
      myself.startLoop()
    }



    // this.vueInstance.$options.scene.onPointerPick = function () {
    //   myself.startStopLoop(500)
    // }


    this.vueInstance.$options.scene.onPointerUp = function () {
      myself.stopLoop()
    }

    window.addEventListener('resize', function () {      
      myself.vueInstance.$options.engine.resize()
    })

    this.vueInstance.$options.engine.stopRenderLoop()
    this.rend()

    let index = 0

    this.vueInstance.$options.scene.registerBeforeRender(function () {
      

      if (myself.points.length > 0 && index < myself.points.length) {

        let position = myself.points[index]

        if (!isNaN(position.x)) {
          myself.emitter1.position.x = position.x
          myself.emitter1.position.y = .1
          myself.emitter1.position.z = position.y
          index++
        }
        
      } else if(index > 1){
        myself.points = []
        index = 0
        myself.stopLoop()
      }

    })


   

  }

  rend () {
    //this.animateHud()
    this.vueInstance.$options.scene.render()
  }

  startLoop () {
    
    this.vueInstance.$options.engine.runRenderLoop(this.rend)
  }

  stopLoop () {
    console.log('stop');  
    this.vueInstance.$options.engine.stopRenderLoop()
  }

  startStopLoop (time) {
    
    let self = this
    self.startLoop()
    setTimeout(self.stopLoop, time !== undefined ? time : 1000)
  }

  addFog (color, intensity) {
    this.vueInstance.$options.scene.fogMode = BABYLON.Scene.FOGMODE_EXP
    this.vueInstance.$options.scene.fogDensity = intensity
    this.vueInstance.$options.scene.fogColor = new BABYLON.Color3.FromHexString(color)
  }

  initHud () {
    let scene = this.vueInstance.$options.scene
    this.atlas = createAtlas(process.env.VUE_APP_BASE_PATH + 'img/hud.png', process.env.VUE_APP_BASE_PATH + 'img/hud.json', scene, BABYLON)
    this.meshHud = this.atlas.makeSpriteMesh()
    this.meshHud.position.y = 0.05
    this.meshHud.rotation.x = Math.PI / 2
    this.meshHud.material.opacityTexture = this.meshHud.material.diffuseTexture
    this.meshHud.isVisible = false

    this.meshHudNarration = this.atlas.makeSpriteMesh()
    this.meshHudNarration.position.y = 0.05
    this.meshHudNarration.rotation.x = Math.PI / 2
    this.meshHudNarration.material.opacityTexture = this.meshHudNarration.material.diffuseTexture
    this.meshHudNarration.isVisible = false
  }

  animateHud () {
    if (!this.atlas.frames.length) return
    this.num = (this.num + 1) % this.atlas.frames.length
    this.atlas.setMeshFrame(this.meshHud, this.atlas.frames[this.num])
    this.atlas.setMeshFrame(this.meshHudNarration, this.atlas.frames[this.num])
  }

  drawStadium (stadium) {
    let self = this

    this.vueInstance.$options.engine.displayLoadingUI()

    BABYLON.SceneLoader.ImportMeshAsync('', process.env.VUE_APP_BASE_PATH + 'models/', stadium, this.vueInstance.$options.scene)
      .then(result => {
        self.vueInstance.$options.engine.hideLoadingUI()
        self.startStopLoop(3000)
      })
  }

  createSphere (name) {
    var sphere = BABYLON.Mesh.CreateSphere('sphere1', 16, 2, this.vueInstance.$options.scene)
    sphere.position.y = 1

    var theta = 0
    this.vueInstance.$options.scene.registerBeforeRender(function () {
      sphere.position.y = 1 + Math.abs(Math.sin(theta))
      theta += 0.09
    })
  }

  createBox (x, y) {
    var box3 = BABYLON.Mesh.CreateBox('b3', 0.2, this.vueInstance.$options.scene)
    let coordinates = this.calculateXZCorrdinates(x, y, null, false)

    box3.position.y = 0.1
    box3.position.x = coordinates.x
    box3.position.z = coordinates.z

      var rect1 = new BABYLON.GUI.Rectangle('rectangle' + x + '-' + y)
    rect1.width = 0.04
    rect1.height = '15px'
    rect1.cornerRadius = 20
    rect1.color = 'white'
    rect1.thickness = 1
    rect1.hasAlpha = true
    rect1.background = '#80333333'
    this.advancedTexture.addControl(rect1)
    rect1.linkWithMesh(box3)
    rect1.linkOffsetY = -15

    var labelNumber = new BABYLON.GUI.TextBlock()
    labelNumber.text = x + ',' + y
    labelNumber.fontSize = '5'
    labelNumber.fontWeight = 'bold'
    rect1.addControl(labelNumber)
  }



  createBoxPorteria (x, y, identifier, tipo) {


    if(this.temporaldrawedLinesIdentifier[identifier] == undefined){
      this.temporaldrawedLinesIdentifier[identifier] = []
    }

    var box3 = BABYLON.Mesh.CreateSphere('sphere1', 16, .1, this.vueInstance.$options.scene)
    //var box3 = BABYLON.Mesh.CreateBox('b3', 0.2, this.vueInstance.$options.scene)

    var mat = new BABYLON.StandardMaterial("dog", this.vueInstance.$options.scene);
    mat.diffuseTexture = new BABYLON.Texture(process.env.VUE_APP_BASE_PATH + 'models/Texturas/soccer_'+tipo+'.png', this.vueInstance.$options.scene);
    mat.diffuseTexture.hasAlpha = true;
    mat.backFaceCulling = true;
    box3.material = mat
    let coordinates = this.calculateXZCorrdinates(x, y, false, false)

    //console.log(coordinates);

    box3.position.y = coordinates.x
    box3.position.x = coordinates.z
    box3.position.z = 10//

    this.temporaldrawedLinesIdentifier[identifier].push(box3)
/*
      var rect1 = new BABYLON.GUI.Rectangle('rectangle' + x + '-' + y)
    rect1.width = 0.04
    rect1.height = '15px'
    rect1.cornerRadius = 20
    rect1.color = 'white'
    rect1.thickness = 1
    rect1.hasAlpha = true
    rect1.background = '#80333333'
    this.advancedTexture.addControl(rect1)
    rect1.linkWithMesh(box3)
    rect1.linkOffsetY = -15

    var labelNumber = new BABYLON.GUI.TextBlock()
    labelNumber.text = x + ',' + y
    labelNumber.fontSize = '5'
    labelNumber.fontWeight = 'bold'
    rect1.addControl(labelNumber)
    */

    this.startStopLoop(100)
  }


  load3DPlayer () {
    let self = this

    let texture = new BABYLON.Texture(process.env.VUE_APP_BASE_PATH + 'models/Texturas/UNIFORME-' + this.idLocal + '-L.png', this.vueInstance.$options.scene, false, false)
    let texture2 = new BABYLON.Texture(process.env.VUE_APP_BASE_PATH + 'models/Texturas/UNIFORME-' + this.idVisita + '-P.png', this.vueInstance.$options.scene, false, false)

    BABYLON.SceneLoader.ImportMeshAsync('', process.env.VUE_APP_BASE_PATH + 'models/', 'Player.glb', this.vueInstance.$options.scene).then(result => {
      self.localPlayeres3d = result

      result.animationGroups.map(animation => {
        animation.start()
      })

      result.meshes[1].material.backFaceCulling = false
      result.meshes[2].material.backFaceCulling = false
      result.meshes[3].material.backFaceCulling = false
      result.meshes[1].material.albedoTexture = texture

      result.meshes.map(mesh => {
        mesh.isVisible = false
      })
    })

    BABYLON.SceneLoader.ImportMeshAsync('', process.env.VUE_APP_BASE_PATH + 'models/', 'Player.glb', this.vueInstance.$options.scene).then(result => {
      self.visitaPlayeres3d = result

      result.animationGroups.map(animation => {
        animation.start()
      })

      result.meshes[1].material.backFaceCulling = false
      result.meshes[2].material.backFaceCulling = false
      result.meshes[3].material.backFaceCulling = false
      result.meshes[1].material.albedoTexture = texture2

      result.meshes.map(mesh => {
        mesh.isVisible = false
        mesh.rotation.y = Math.PI
      })
    })
  }

   async loadPinPlayer () {
    let self = this

    let coloresLocal = self.getColorsbyClubId(this.idLocal)
    let coloresVisita = self.getColorsbyClubId(this.idVisita)


   await BABYLON.SceneLoader.ImportMeshAsync('', process.env.VUE_APP_BASE_PATH + 'models/', 'Pin_003.glb', this.vueInstance.$options.scene).then(result => {
     
      self.localPlayeres3d = result

      result.meshes[1].material.backFaceCulling = false
      // result.meshes[2].material.backFaceCulling = false
      // result.meshes[1].material.albedoColor = new BABYLON.Color3.FromHexString(coloresLocal.colorLocal)
      // result.meshes[2].material.albedoColor = new BABYLON.Color3.FromHexString(coloresLocal.colorLocal)
      result.meshes[1].material.albedoColor = new BABYLON.Color3.FromHexString('#FF0000')

     result.meshes[1].scaling.scaleInPlace(2.5);

     result.meshes[1].rotate(BABYLON.Axis.X, Math.PI/2, BABYLON.Space.WORLD);
     result.meshes[1].rotate(BABYLON.Axis.Z, Math.PI / 2, BABYLON.Space.WORLD);
     result.meshes[1].rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD);
     

      result.meshes.map(mesh => {
        mesh.isVisible = false
        // mesh.rotation.y = Math.PI
      })
    })

    BABYLON.SceneLoader.ImportMeshAsync('', process.env.VUE_APP_BASE_PATH + 'models/', 'Pin_003.glb', this.vueInstance.$options.scene).then(result => {
      self.visitaPlayeres3d = result

      result.meshes[1].material.backFaceCulling = false
      // result.meshes[2].material.backFaceCulling = false
      result.meshes[1].material.albedoColor = new BABYLON.Color3.FromHexString('#0000ff')
      result.meshes[1].scaling.scaleInPlace(2.5);

      result.meshes[1].rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD);
      result.meshes[1].rotate(BABYLON.Axis.Z, Math.PI / 2, BABYLON.Space.WORLD);
      result.meshes[1].rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD);
      // result.meshes[1].rotation.y = Math.PI
      // result.meshes[2].material.albedoColor = new BABYLON.Color3.FromHexString(coloresVisita.colorVisita)

      result.meshes.map(mesh => {
        mesh.isVisible = false
        //  mesh.rotation.y = Math.PI
      })
    })
  }

  async loadPinPlayerNormal() {
    let self = this

    let coloresLocal = self.getColorsbyClubId(this.idLocal)
    let coloresVisita = self.getColorsbyClubId(this.idVisita)


    await BABYLON.SceneLoader.ImportMeshAsync('', process.env.VUE_APP_BASE_PATH + 'models/', 'Pin_003.glb', this.vueInstance.$options.scene).then(result => {

      self.localPlayeres3d = result

      result.meshes[1].material.backFaceCulling = false
      // result.meshes[2].material.backFaceCulling = false
      // result.meshes[1].material.albedoColor = new BABYLON.Color3.FromHexString(coloresLocal.colorLocal)
      // result.meshes[2].material.albedoColor = new BABYLON.Color3.FromHexString(coloresLocal.colorLocal)
      result.meshes[1].material.albedoColor = new BABYLON.Color3.FromHexString('#FF0000')

      // result.meshes[1].scaling.scaleInPlace(1.7);

      result.meshes.map(mesh => {
        mesh.isVisible = false
        // mesh.rotation.y = Math.PI
      })
    })

    BABYLON.SceneLoader.ImportMeshAsync('', process.env.VUE_APP_BASE_PATH + 'models/', 'Pin_003.glb', this.vueInstance.$options.scene).then(result => {
      self.visitaPlayeres3d = result

      result.meshes[1].material.backFaceCulling = false
      // result.meshes[2].material.backFaceCulling = false
      result.meshes[1].material.albedoColor = new BABYLON.Color3.FromHexString('#0000ff')
      result.meshes[1].scaling.scaleInPlace(1.7);
      // result.meshes[1].rotation.y = Math.PI
      // result.meshes[2].material.albedoColor = new BABYLON.Color3.FromHexString(coloresVisita.colorVisita)

      result.meshes.map(mesh => {
        mesh.isVisible = false
        //  mesh.rotation.y = Math.PI
      })
    })
  }

  async loadColorsClubs (datosPartido) {
    await axios.get(`https://liga.apilmx.com/v1/mxmInformeArbitral/coloresClubs?&idTorneo=${datosPartido.idTorneo}&idDivision=${datosPartido.idDivision}&idTemporada=${datosPartido.idTemporada}`)
      .then(resp => {
        this.colorsClub = resp.data
      })
      .catch(erro => {
        console.log('ocurrio un error al cargar los colores', erro)
      })
  }

  getColorsbyClubId (idClub) {
    let output = {}
    this.colorsClub.map(element => {
      if (element.idClub == idClub) {
        output = element
      }
    })

    return output
  }

  async moveCameraTo (to) {
    let self = this

    let animationcamera = new BABYLON.Animation(
      'myAnimationcamera',
      'position',
      30,
      BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    )

    let cameraClone = self.vueInstance.$options.camera.position.clone()

    console.log(cameraClone)

    let keys = []

    keys.push({
      frame: 0,
      value: cameraClone
    })

    keys.push({
      frame: 100,
      value: new BABYLON.Vector3(to.x, to.y, to.z)
    })

    animationcamera.setKeys(keys)

    self.vueInstance.$options.camera.animations = []
    self.vueInstance.$options.camera.animations.push(animationcamera)

    this.startLoop()

    let animation = self.vueInstance.$options.scene.beginAnimation(self.vueInstance.$options.camera, 0, 100, false, 3)

    await animation.waitAsync()
    this.stopLoop()
  }

  async moveMeshTo(mesh, to) {
    let self = this

    let animationcamera = new BABYLON.Animation(
      'myAnimationcamera',
      'position',
      30,
      BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    )

    let meshclone = mesh.position.clone()

    let keys = []

    keys.push({
      frame: 0,
      value: meshclone
    })

    keys.push({
      frame: 100,
      value: new BABYLON.Vector3(to.x, to.y, to.z)
    })

    animationcamera.setKeys(keys)

    mesh.animations = []
    mesh.animations.push(animationcamera)

    this.startStopLoop(1000)

    let animation = self.vueInstance.$options.scene.beginAnimation(mesh, 0, 100, false, 3)

    await animation.waitAsync()
    
  }

  calculateXZCorrdinates (x, z, aliniation, differentCoordinates) {
    // console.log('x: ' + x + ' z: ' + z)
    let finalX
    let finalZ

    if (differentCoordinates) {

      finalX = 1 * (this.golstatsNewCoordinates.zInicio + ((z / 100) * this.golstatsNewCoordinates.zTotal))
      finalZ = -1 * (this.golstatsNewCoordinates.xInicio + ((x / 100) * this.golstatsNewCoordinates.xTotal))

    } else if (aliniation) {

      if (this.typeAlineation == 1) {
        finalX = (this.xInicio) + ((x / 100) * (this.xTotal - 1))
        finalZ = (this.zInicio +1) + ((z / 100) * this.zTotal)
      } else {
        finalX = (this.xInicio ) + ((x / 100) * (this.xTotal - 1))
        finalZ = (this.zInicio+1   ) + ((z / 100) * this.zTotal)
      }
    } else {
      finalX = this.xInicio + ((x / 100) * this.xTotal)

      if (this.isPorteria) {
        finalZ = this.zInicio + ((z / 100) * this.zTotal)
      } else {
        finalZ = this.zInicio + ((z / 100) * this.zTotal)
      }
      
    }

    return ({
      x: finalZ,
      z: finalX
    })
  }

  paintAlineations (type) {
    let self = this
    let alineaciones = []
    let meshes = []

    self.hideAliniations(0)
    this.typeActions(type, () => {
      this.typeAlineation = 1
      if (self.localPlayeres3d.painted !== true) {
        meshes = self.localPlayeres3d.meshes
        alineaciones = self.vueInstance.alineacionLocal.alineacion_inicial[0]['alineacion']
        self.vueInstance.alineacionLocal.tempAliniation = []
        self.vueInstance.alineacionLocal.rectanglesIndex = []
        self.localPlayeres3d.painted = true
        self.paintOnceAlineations(alineaciones, meshes, 1)
      } else {
        self.showHidddenMeshes(self.vueInstance.alineacionLocal.tempAliniation)

        self.vueInstance.alineacionLocal.rectanglesIndex.map(nameRect => {
          self.vueInstance.alineacionLocal.tempAliniation[nameRect].isVisible = true
        })
      }
    },
    () => {
      this.typeAlineation = 2
      if (self.visitaPlayeres3d.painted !== true) {
        meshes = self.visitaPlayeres3d.meshes
        alineaciones = self.vueInstance.alineacionVisita.alineacion_inicial[0]['alineacion']
        self.vueInstance.alineacionVisita.tempAliniation = []
        self.vueInstance.alineacionVisita.rectanglesIndex = []
        self.visitaPlayeres3d.painted = true
        self.paintOnceAlineations(alineaciones, meshes, 2)
      } else {
        self.showHidddenMeshes(self.vueInstance.alineacionVisita.tempAliniation)

        self.vueInstance.alineacionVisita.rectanglesIndex.map(nameRect => {
          self.vueInstance.alineacionVisita.tempAliniation[nameRect].isVisible = true
        })
      }
    })
  }

  paintOnceAlineations (alineaciones, meshes, type) {
    let self = this
    let posicionesInicales = []
    alineaciones.map(jugador => {
      let id = jugador.ID
      let x = jugador.X
      let y = jugador.Y
      posicionesInicales.push([id, x, y])

      let coordinates = self.calculateXZCorrdinates(jugador.X, jugador.Y, true)
      self.paintOnePlayer(meshes, coordinates, type, jugador)
    })

    this.typeActions(type, () => {
      self.initialPositions.local = posicionesInicales
    }, () => {
      self.initialPositions.visita = posicionesInicales
    })
  }

  paintOnePlayer (meshes, coordinates, type, jugador) {
    let self = this

    for (let index = 0; index < meshes.length; index++) {
      var instance = meshes[index].createInstance('player_' + index + '_' + jugador.ID)

      instance.parent = null

      instance.position.x = coordinates.x
      instance.position.z = coordinates.z
      instance.position.y = .5
      instance.isVisible = false


      this.showPlayerDescription(self.vueInstance.$options.scene,jugador,instance)
      

      this.typeActions(type, () => {
        // console.log('local ')
        // console.log(self.vueInstance.alineacionLocal)
        
        self.vueInstance.alineacionLocal.tempAliniation.push(instance)
      },
      () => {
        // console.log('visita ')
        // console.log( self.vueInstance.alineacionVisita)
        
        self.vueInstance.alineacionVisita.tempAliniation.push(instance)
      })
    }

    let nameMesh = 'rect_player_' + jugador.ID

    //  var rect1 = new BABYLON.GUI.Rectangle(nameMesh)
    // rect1.width = 0.04
    // rect1.height = '15px'
    // rect1.cornerRadius = 20
    // rect1.color = 'white'
    // rect1.thickness = 1
    // rect1.hasAlpha = true
    // rect1.background = '#80333333'
    // this.advancedTexture.addControl(rect1)
    // rect1.linkWithMesh(instance)
    // rect1.linkOffsetY = -35


    // var labelNumber = new BABYLON.GUI.TextBlock()
    // labelNumber.text = jugador.NUM + ''
    // labelNumber.fontSize = '9'
    // labelNumber.fontWeight = 'bold'
    // rect1.addControl(labelNumber)

    let faceUV = []
    faceUV[0] = new BABYLON.Vector4(0, 0, 1, 1)
    faceUV[2] = new BABYLON.Vector4(0, 0, 1, 1)

    let textureGround = new BABYLON.DynamicTexture('dynamic texture', { width: 256, height: 256}, this.vueInstance.$options.scene)

    let circleMaterial = new BABYLON.StandardMaterial('wheel_mat', this.vueInstance.$options.scene)
    // circleMaterial.diffuseColor = new BABYLON.Color3.FromHexString('#5830CB')
    circleMaterial.diffuseTexture = textureGround

    let font = 'bold 140px monospace'

    // jQuery.getScript("https://sanfrancisco.ca.illuminated.city/js/meshwriter.min.js").then()


    // Writer = BABYLON.MeshWriter(this.vueInstance.$options.scene, { scale: 0.1, defaultFont:"Arial"});
    // text1  = new Writer( 
    //                 "juan",
    //                 {
    //                     "anchor": "center",
    //                     "letter-height": 40,
    //                     "color": "#1C3870",
    //                     "position": {
    //                         "z": 70
    //                     }
    //                 }
    //             );
    

    this.typeActions(type, () => {
      textureGround.drawText(jugador.NUM, 10, 155, font, '#ffffff', '#FF0000', true, true)
    },
    () => {
      textureGround.drawText(jugador.NUM, 10, 155, font, '#ffffff', '#0000ff', true, true)
    })

    //uncomment to use in playernfigure
    // let circle = BABYLON.MeshBuilder.CreateCylinder('circle', { diameter: 1, height: 0.01, tessellation: 24, faceUV: faceUV }, this.vueInstance.$options.scene)
    // circle.material = circleMaterial
    // circle.rotation.y = Math.PI / 2
    // circle.rotation.x = Math.PI
    // circle.rotation.z = Math.PI
    // circle.position.x = coordinates.x + 0.5
    // circle.position.y = 0.01
    // circle.position.z = coordinates.z

    let circle = BABYLON.MeshBuilder.CreateCylinder('circle', { diameter: 1.6, height: 0.01, tessellation: 24, faceUV: faceUV }, this.vueInstance.$options.scene)
    circle.material = circleMaterial
    circle.rotation.y = - Math.PI/2  
    // circle.rotation.x = Math.PI / - 2
    //  circle.rotation.z = Math.PI / - 2

    circle.position.x = coordinates.x 
    circle.position.y = .6
    circle.position.z = coordinates.z 

    // var label = new BABYLON.GUI.TextBlock()
    // label.text = '              ' + jugador.NMBRCRTO
    // rect1.addControl(label)

    this.typeActions(type, () => {
      self.vueInstance.alineacionLocal.rectanglesIndex.push(nameMesh)
      self.vueInstance.alineacionLocal.tempAliniation[nameMesh] = circle
    },
    () => {
      self.vueInstance.alineacionVisita.rectanglesIndex.push(nameMesh)
      self.vueInstance.alineacionVisita.tempAliniation[nameMesh] = circle
    })
  }

  hideAliniations (type) {
    let self = this
    this.hideHud()

    this.typeActions(type, () => {
      if (self.vueInstance.alineacionLocal.tempAliniation !== undefined) {
        self.vueInstance.alineacionLocal.tempAliniation.map(mesh => {
          mesh.isVisible = false
        })

        self.vueInstance.alineacionLocal.rectanglesIndex.map(nameRect => {
          self.vueInstance.alineacionLocal.tempAliniation[nameRect].isVisible = false
        })
      }
    },
    () => {
      if (self.vueInstance.alineacionVisita.tempAliniation !== undefined) {
        self.vueInstance.alineacionVisita.tempAliniation.map(mesh => {
          mesh.isVisible = false
        })

        self.vueInstance.alineacionVisita.rectanglesIndex.map(nameRect => {
          self.vueInstance.alineacionVisita.tempAliniation[nameRect].isVisible = false
        })
      }
    })
    this.startStopLoop()
  }

  showHidddenMeshes (meshes) {
    meshes.map(mesh => {
      //  mesh.isVisible = true
    })
  }
 
  addHud (player) {
    let coordinates = this.calculateXZCorrdinates(player.X, player.Y, true)
    this.meshHud.position.x = coordinates.x
    this.meshHud.position.z = coordinates.z
    this.meshHud.isVisible = true
    this.startStopLoop(1500)
  }

  hideHud () {
    this.meshHud.isVisible = false
  }

  create2dTextBlock (text, size, weight, color) {
    let labelBlock = new BABYLON.GUI.TextBlock()
    labelBlock.text = text
    labelBlock.color = color
    labelBlock.fontSize = size
    labelBlock.fontWeight = weight
    return labelBlock
  }

  create2dImg (name, path, width, height) {
    let image = new BABYLON.GUI.Image(name, path + name)

    if (width !== undefined) {
      image.width = width
    }

    if (height !== undefined) {
      image.height = height
    }
    return image
  }

  typeActions (type, localFucntion, visitaFucntion) {

    if (type == 1) {
      localFucntion()
    } else if (type == 2) {
      visitaFucntion()
    } else if (type == 0) {
      localFucntion()
      visitaFucntion()
    }
  }

  addYardEvent (jugada, x, y, z) {

    BABYLON.Tools.CorsBehavior = 'anonymous'

    // var image = new BABYLON.GUI.Image('but', 'https://s3.amazonaws.com/lmxwebsite/docs/archdgtl/AfldDrct/logos64x64/' + jugada.IdClub + '/' + jugada.IdClub + '.png')
    // image.width = 0.2
    // image.height = '40px'


    // this.vueInstance.$options.camera.setTarget(new BABYLON.Vector3(x, y, z))

    let icon = this.create2dImg(jugada.IdDetalle + '.png', process.env.VUE_APP_BASE_PATH + 'img/eventos/', '32px')
    icon.paddingRight = '60px'
    icon.paddingTop = '0px'
    if (this.narrationObjectsWasInit === false) {
      this.rectangleContainerNarration = new BABYLON.GUI.Rectangle()
      this.rectangleContainerNarration.width = 0.15
      this.rectangleContainerNarration.height = '15px'
      this.rectangleContainerNarration.cornerRadius = 20
      this.rectangleContainerNarration.color = 'white'
      this.rectangleContainerNarration.thickness = 1
      this.rectangleContainerNarration.hasAlpha = true
      this.rectangleContainerNarration.background = '#80333333'
      this.rectangleContainerNarration.isVisible = true
      this.narrationObjectsWasInit = true
      this.narracionTextDetalle = this.create2dTextBlock(jugada.Detalle, 4, '5', '#E0FFFF')
      this.narracionTextMinute = this.create2dTextBlock(jugada.Minuto + "'", 6, 'bold', '#E0FFFF')
    } else {
      this.rectangleContainerNarration.isVisible = true
      this.rectangleContainerNarration.clearControls()
      this.narracionTextDetalle.text = jugada.Detalle
      this.narracionTextMinute.text = jugada.Minuto + "'"
    }

    this.narracionTextDetalle.paddingTop = '5px'
    this.narracionTextDetalle.paddingRight = '0px'
    this.narracionTextMinute.paddingBottom = '5px'

    this.advancedTexture.addControl(this.rectangleContainerNarration)

    this.rectangleContainerNarration.addControl(icon)
    // this.rectangleContainerNarration.addControl(image)
    this.rectangleContainerNarration.addControl(this.narracionTextMinute)
    this.rectangleContainerNarration.addControl(this.narracionTextDetalle)

    this.meshHudNarration.position.x = x
    // this.meshHudNarration.position.y = y
    this.meshHudNarration.position.z = z
    this.meshHudNarration.isVisible = true
    this.rectangleContainerNarration.linkWithMesh(this.meshHudNarration)
    this.rectangleContainerNarration.linkOffsetY = -35
    this.startStopLoop()
  }

  hideMeshNarration () {
    this.meshHudNarration.isVisible = false
    this.rectangleContainerNarration.isVisible = false
  }


  paintAnimationSequence (sequence) {
    let self = this
    let path = []
    let scene = self.vueInstance.$options.scene

    self.deleteTemporalLines(true)

    let x1,x2,y1,y2,x3,y3

    sequence.jugadas.map(play =>{
      
      let coordinatesA = this.calculateXZCorrdinates(play.x1, parseFloat(play.y1), null, true)
      let coordinatesB = this.calculateXZCorrdinates(play.x2, parseFloat(play.y2), null, true)
      let coordinatesC = this.calculateXZCorrdinates(play.x3, parseFloat(play.y3), null, true)

      x1 = coordinatesA.x
      x2 = coordinatesB.x
      y1 = coordinatesA.z
      y2 = coordinatesB.z
      x3 = coordinatesC.x
      y3 = coordinatesC.z

      let innerPoints = self.lines.getPointsToVector(60,x1,y1,x2,y2,x3,y3)
      
      self.points = self.points.concat(innerPoints)
    })



    self.emitter1 = BABYLON.Mesh.CreateBox("emitter0", 0.1, scene);
    self.emitter1.isVisible = false;

    self. particleSystem2 = new BABYLON.ParticleSystem("particles", 4000, scene);
    self.particleSystem2.particleTexture = new BABYLON.Texture(process.env.VUE_APP_BASE_PATH + 'img/Flare.png', scene);

    self.particleSystem2.minSize = 0.3;
    self.particleSystem2.maxSize = .6;
    self.particleSystem2.minEmitPower = 2.0;
    self.particleSystem2.maxEmitPower = 2.0;
    self.particleSystem2.minLifeTime = 1;
    self.particleSystem2.maxLifeTime = 10;
    self.particleSystem2.emitter = self.emitter1;
    self.particleSystem2.emitRate = 500;
    self.particleSystem2.blendMode = BABYLON.ParticleSystem.BLENDMODE_ONEONE;
    //self.particleSystem2.blendMode = BABYLON.ParticleSystem.BLENDMODE_STANDARD;
    self.particleSystem2.minEmitBox = new BABYLON.Vector3(0, 0, 0);
    self.particleSystem2.maxEmitBox = new BABYLON.Vector3(0, 0, 0);
    //self.particleSystem2.gravity = new BABYLON.Vector3(0, -0.5, 0);
    self.particleSystem2.direction1 = new BABYLON.Vector3(0, 0, 0);
    self.particleSystem2.direction2 = new BABYLON.Vector3(0, 0, 0);
    self.particleSystem2.start();

    this.temporaldrawedLines.push(self.emitter1)
    this.temporaldrawedLines.push(self.particleSystem2)


    this.startLoop()


  }

  paintEventLine (x1, y1, x2, y2, x3, y3, identifier) {
    let numPlayer = 0

    if(this.temporaldrawedLinesIdentifier[identifier] == undefined){
      this.temporaldrawedLinesIdentifier[identifier] = []
    }

    let coordinatesA = this.calculateXZCorrdinates(x1, y1, null, false)
    let coordinatesB = this.calculateXZCorrdinates(x2, y2, null, false)
    let coordinatesC = this.calculateXZCorrdinates(x3, y3, null, false)
    
    let path = [
      new BABYLON.Vector3(coordinatesA.x, 0.1, coordinatesA.z),
      new BABYLON.Vector3(coordinatesB.x, 0.1, coordinatesB.z),
      new BABYLON.Vector3(coordinatesC.x, 0.1, coordinatesC.z)
    ]

    let lengthL = Object.keys(this.temporaldrawedLinesIdentifier).length


    console.log(Object.keys(this.temporaldrawedLinesIdentifier).length);

   // console.log(x1, y1, x2, y2, x3, y3);


    let linetoDraw = this.lines.line2D('line', { path: path, width: 0.09, color:this.simpleColors[lengthL]}, this.vueInstance.$options.scene)
   
    
    this.temporaldrawedLinesIdentifier[identifier].push(linetoDraw)

    let faceUV = []   
    faceUV[0] = new BABYLON.Vector4(0, 0, 1, 1)
    faceUV[2] = new BABYLON.Vector4(0, 0, 1, 1)

    let textureGround = new BABYLON.DynamicTexture('dynamic texture', { width: 512, height: 256 }, this.vueInstance.$options.scene)

    let circleMaterial = new BABYLON.StandardMaterial('wheel_mat', this.vueInstance.$options.scene)
    // circleMaterial.diffuseColor = new BABYLON.Color3.FromHexString('#5830CB')
    circleMaterial.diffuseTexture = textureGround
    
    let font = 'bold 180px monospace'
    textureGround.drawText(numPlayer, 175, 155, font, 'white', '#5830CB', true, true)

    let circle = BABYLON.MeshBuilder.CreateCylinder('circle', { diameter: 0.5, height: 0.01, tessellation: 24, faceUV: faceUV }, this.vueInstance.$options.scene)
    circle.material = circleMaterial
    circle.rotation.y = Math.PI / 2
    circle.rotation.x = Math.PI
    circle.rotation.z = Math.PI
    circle.position.x = coordinatesC.x
    circle.position.y = 0.1
    circle.position.z = coordinatesC.z

    this.temporaldrawedLinesIdentifier[identifier].push(circle)

    this.startStopLoop(100)
  }

  deleteTemporalLines (startStop) {
    this.temporaldrawedLines.map(line => {
      line.dispose()
    })
    this.temporaldrawedLines = []

    if (startStop == undefined) {
      this.startStopLoop(100)
    }
    
  }


  deleteTemporalLinesIdentifier (identifier,startStop) {

    if(this.temporaldrawedLinesIdentifier[identifier]){
      this.temporaldrawedLinesIdentifier[identifier].map(line => {
        line.dispose()
      })
      this.temporaldrawedLinesIdentifier[identifier] = []
  
      if (startStop == undefined) {
        this.startStopLoop(100)
      }
    }
  
    
  }

  setAlineations (allPlayers) {

    allPlayers.map(player => {
      this.allPlayers[player.ID] = player
    })
  }

  setPositions (positions) {
    this.allPosition = positions
  }

  setTeams (local, visita) {
    this.equipoLocal = local
    this.equipoVisita = visita
  }

  setClubs (local, visita) {    
    this.idLocal = local
    this.idVisita = visita
  }

  updateAllPlayersInitialPositions (type) {
    let self = this

    this.typeActions(type, () => {
      self.typeAlineation = 1
      self.calculatePositionsMesh(self.initialPositions.local, true, true)
    },
    () => {
      self.typeAlineation = 2
      self.calculatePositionsMesh(self.initialPositions.visita, false, true)
    })
  }

  updateAllPlayeresPosition(minute, type) {
    let self = this
    minute = minute === 0 ? 1 : minute
    let positions = this.allPosition[minute - 1]



    // console.log('local ')
    // console.log(self.vueInstance.alineacionLocal)
    // console.log('visita ')
    // console.log(self.vueInstance.alineacionVisita)


    let output = positions === undefined

    if (output === false) {
      this.typeActions(type, () => {
        this.calculatePositionsMesh(positions.local, true, false)
      },
        () => {
          this.calculatePositionsMesh(positions.visit, false, false)
        })


    }
    return output
  }

  calculatePositionsMesh (positions, local, initial) {
    let self = this

    positions.map(position => {
      let player = position[0]
      let x = position[1]
      let y = position[2]

      let coordinates = self.calculateXZCorrdinates(x, y, initial)

      // for (let index = 0; index < 4; index++) {
      for (let index = 0; index < 2; index++) {
        let playerMesh = self.vueInstance.$options.scene.getMeshByName('player_' + index + '_' + player)

        if (playerMesh === undefined || playerMesh === null) {
          let camiseta = 'X'

          if (local) {

            self.equipoLocal.suplentes.map(sustitution => {

              if (sustitution.idJugador === player) {
                camiseta = sustitution.numeroCamiseta
              }

            })

            self.paintOnePlayer(self.localPlayeres3d.meshes, coordinates, local, { ID: player, NUM: camiseta })

          } else {

            self.equipoVisita.suplentes.map(sustitution => {

              if (sustitution.idJugador === player) {
                camiseta = sustitution.numeroCamiseta
              }

            })

            self.paintOnePlayer(self.visitaPlayeres3d.meshes, coordinates, local, { ID: player, NUM: camiseta })
          }
          playerMesh = this.vueInstance.$options.scene.getMeshByName('player_' + index + '_' + player)
        }

        if (local) {
          let innerMesh = self.vueInstance.alineacionLocal.tempAliniation['rect_player_' + player]
          innerMesh.isVisible = true
          // self.vueInstance.alineacionLocal.tempAliniation['rect_player_' + player].position.x = coordinates.x
          // self.vueInstance.alineacionLocal.tempAliniation['rect_player_' + player].position.z = coordinates.z
          // self.vueInstance.alineacionLocal.tempAliniation['rect_player_' + player].isVisible = true
          self.moveMeshTo(innerMesh, { x: coordinates.x, y: innerMesh.position.y, z: coordinates.z })
        } else {
          let innerMesh = self.vueInstance.alineacionVisita.tempAliniation['rect_player_' + player]
          innerMesh.isVisible = true
          // self.vueInstance.alineacionVisita.tempAliniation['rect_player_' + player].position.x = coordinates.x
          // self.vueInstance.alineacionVisita.tempAliniation['rect_player_' + player].position.z = coordinates.z
          // self.vueInstance.alineacionVisita.tempAliniation['rect_player_' + player].isVisible = true
          self.moveMeshTo(innerMesh, { x: coordinates.x, y: innerMesh.position.y, z: coordinates.z })
        }

        self.moveMeshTo(playerMesh, { x: coordinates.x, y: playerMesh.position.y, z: coordinates.z})

        // playerMesh.position.x = coordinates.x + 0.5
        // playerMesh.position.z = coordinates.z
        playerMesh.isVisible = false
      }

    })
  }

  showPlayerDescription (scene, jugador, mesh) {    
    
    let image = this.create2dImg(jugador.ID,'https://citec.futbol/api/v1/player/image/')
    
    let rect1 = new BABYLON.GUI.Rectangle()
    this.advancedTexture.addControl(rect1)
    rect1.width = "150px"
    rect1.height = "150px"
    rect1.thickness = 2
    rect1.linkOffsetX = "70px"
    rect1.linkOffsetY = "-100px"
    rect1.transformCenterX = 0
    rect1.transformCenterY = 1
    rect1.background = "grey"
    rect1.alpha = 0.7
    rect1.scaleX = 0
    rect1.addControl(image)
    rect1.scaleY = 0
    rect1.cornerRadius = 10
    rect1.linkWithMesh(mesh)

    let text1 = new BABYLON.GUI.TextBlock()
    text1.text = jugador.NMBRCRTO
    text1.color = "White"
    text1.fontSize = 14
    text1.textWrapping = true
    text1.textVerticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_TOP
    text1.background = '#006994'
    rect1.addControl(text1)
    text1.alpha = (1 / text1.parent.alpha)
    // text1.paddingTop = "20px"
    // text1.paddingBottom = "20px"
    // text1.paddingLeft = "20px"
    // text1.paddingRight = "20px"

    let actionManager = new BABYLON.ActionManager(scene)
    mesh.actionManager = actionManager

    let scaleXAnimation = new BABYLON.Animation("myAnimation", "scaleX", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT)
    let scaleYAnimation = new BABYLON.Animation("myAnimation", "scaleY", 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT)

    var keys = []

    keys.push({
      frame: 0,
      value: 0
    });
    keys.push({
      frame: 10,
      value: 1
    });

    scaleXAnimation.setKeys(keys);
    scaleYAnimation.setKeys(keys);
    rect1.animations = [];
    rect1.animations.push(scaleXAnimation);
    rect1.animations.push(scaleYAnimation);

    actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
      scene.beginAnimation(rect1, 0, 10, false);
    }));
    //if hover is over remove highlight of the mesh
    actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
      scene.beginAnimation(rect1, 10, 0, false);
    }));

  }
}
