import BaseAPI from "./BaseAPI";

export default class GolstatsServices{
  
  constructor (vueInstance) {
    this.apiClient = new BaseAPI(vueInstance)
    this.store = vueInstance.$store
    this.base = process.env.VUE_APP_URL_AZURE_CITEC
  }

  pasesPartidoJugadorSelecciones(params){

    return this.apiClient.post(`https://iad360.citec.futbol/api/CitecManagerServices?service=pasesPartidoJugadorSelecciones`, params)
  
  }


  tirosPartidoJugadorSelecciones(params){

    return this.apiClient.post(`https://iad360.citec.futbol/api/CitecManagerServices?service=tirosPartidoJugadorSelecciones`, params)
  
  }

  estadisticaAcumuladajugador(params){

    return this.apiClient.post(`https://iad360.citec.futbol/api/CitecManagerServices?service=variablesSeleccionesIds`, params)
  
  }


  eventosPartidoJugadorSeleccion(params){
 
    return this.apiClient.post(`https://iad360.citec.futbol/api/CitecManagerServices?service=eventosPartidoJugadorSeleccion`, params)

  }


  login(data){
    
    return  this.store.dispatch(process.env.VUE_APP_AUTH_REQUEST, data)
  }
}