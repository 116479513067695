import Vue from 'vue'
import App from './App.vue'
import router from './store/index'
import store from './store'
import VueSilentbox from 'vue-silentbox'
//import 'vue-plyr'
import widget from './componentes/defensivas.vue'
import analisisDefensivo from './componentes/analisisDefensivo.vue'
import analisisOfensivo from './componentes/analisisOfensivo.vue'
import analisisOfensivosGoles from './componentes/analisisOfensivosGoles.vue'
import videosJugador from './componentes/videosJugador.vue'

import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

Vue.use(VueSilentbox)
Vue.config.productionTip = false



// new Vue is not needed anymore, as you're initializing the app through vue-custom-element
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// use vue-custom-element

App.router = router

widget.store = store
analisisDefensivo.store = store
analisisOfensivo.store = store
analisisOfensivosGoles.store = store
videosJugador.store = store

Vue.customElement('citec-widget', widget)
Vue.customElement('citec-analisisdefensivo', analisisDefensivo)
Vue.customElement('citec-analisisofensivo', analisisOfensivo)
Vue.customElement('citec-analisisofensivosgoles', analisisOfensivosGoles)
Vue.customElement('citec-videos',videosJugador)

