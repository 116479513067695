<template>
    <div v-if="dataJugador!=undefined" class="classbycitec" :style="'background-image: url(' + require('../assets/images/powerbycitec6.png') + ')'">
        <div class="bg-paper-light p-2 text-center rounded bg-opacity-60 h-24 flex flex-col relative" v-for="(title, index) in titlesInner" :key="titlewidget + index ">
            <div class="text-sm text-base-100 h-7 max-w-full whitespace-nowrap overflow-hidden overflow-ellipsis">
                {{title}} <img class="justify-center items-center" /> 
            </div>
            <div class="text-base-100 text-4xl md:text-5xl font-bold h-full w-full flex justify-center items-center" v-if="dataJugador[innervariable[index]]>0">
                {{dataJugador[innervariable[index]]}}
            </div> 
            <div class="text-base-100 text-4xl md:text-5xl font-bold h-full w-full flex justify-center items-center" v-else>
                0
            </div>        
        </div>
    </div>
</template>

<script>

import GolstatsServices from '@/services/GolstatsServices'

export default {
  name: "defensivas",

  data: () => ({
    golstatsservices: undefined,
    dataJugador:undefined,
    innervariable:[],
    titlesInner:[]
  }),
  props:{
      idPlayer:{
        type: Number,
      },
      competicion:{

      },
      titles:{
        type:String,
        required: true
      },
      titlewidget:{
        type:String,
        required: true
      },
      variable:{
        type:String,
        required: true
      },
      columns:{
        type:Number
      },
      rows:{
        type:Number
      }
  },

  components: {},

  methods: {

    async invocaData(){

        let dataAcces = {"mail":"pasaporte@axeleratum.com","pass":"sJJett_DffSv#767466554"}
        await this.golstatsservices.login(dataAcces )
        .then(result=>{
        }).catch(error =>{
            console.log(error);
        })

    },

     async invocaDataJugadores(){

        this.titlesInner = this.titles.split(",")
        this.titlewidget = this.titlewidget
        this.innervariable = this.variable.split(",")

        let params = {
            "idPlayer": this.idPlayer,
            "competicion": this.competicion,
            "variables":this.innervariable   
        }

        await this.golstatsservices.estadisticaAcumuladajugador(params)
        .then(result=>{
            this.dataJugador = result.data.data

        }).catch(error =>{
            console.log(error);
        })
    }

  },
  async mounted() {

    this.golstatsservices = new GolstatsServices(this)
    await this.invocaData()
    this.invocaDataJugadores()

  },
};
</script>

<style>
.classbycitec {
     background-color: transparent !important;
     background-size: 100px;
     background-repeat: no-repeat;
     background-position:right bottom
}

</style>