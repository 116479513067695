<template>
    <div class="grid grid-cols-12 gap-1 ">
        <div class="col-span-8 bg-paper-light px-0 py-0 rounded bg-opacity-60">
            <div class="flex justify-between px-2 py-2">
                <span class="text-base-100">Visualización</span>
                <div class="flex">
                    <label class="inline-flex relative items-center cursor-pointer">
                        <span
                            class="ml-0 mr-2 text-sm font-medium text-base-100 dark:text-gray-300">2D</span>
                    </label>
                    <label for="default-toggle" class="inline-flex relative items-center cursor-pointer">
                        <input type="checkbox" value="" id="default-toggle" class="sr-only peer"  @change="change2D3D()" v-model="check3D">
                        <div
                            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-base-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                        </div>
                        <span class="ml-3 text-sm font-medium text-base-100 dark:text-gray-300">3D</span>
                    </label>
                </div>
                <div></div>
            </div>
            <div class="flex w-full py-0">
                <div class="md:flex items-center justify-center">
                    <canvas id="contenedor-canvas" class="p-0 w-full rounded"></canvas> 
                </div>
            </div>
        </div>
        <div class="col-span-4 bg-paper-light p-2 rounded bg-opacity-60">
            <div>
                <span class="text-base-100">Categorías</span>

                <div class="flex flex-col divide-y divide-solid divide-gray-200/10">

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="balonesRecuperados" type="checkbox" value="" v-model="balonesRecuperados" 
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="balonesRecuperados"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Balones recuperados (en disputa)
                        </label>
                    </div>

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="balonesRecuperadosSinDisputa" type="checkbox" value="" v-model="balonesRecuperadosSinDisputa"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="balonesRecuperadosSinDisputa"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Balones recuperados (sin disputa)
                        </label>
                    </div>

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="unovsunoexitososdefensivos" type="checkbox" value="" v-model="unovsunoexitososdefensivos"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="unovsunoexitososdefensivos"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            1vs1 exitosos defensivos 
                        </label>
                    </div>

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="unovsunonoexitososdefensivos" type="checkbox" value="" v-model="unovsunonoexitososdefensivos"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="unovsunonoexitososdefensivos"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            1vs1 no exitosos defensivos
                        </label>
                    </div>


                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="balonesganadosenareapropia" type="checkbox" value="" v-model="balonesganadosenareapropia"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="balonesganadosenareapropia"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Balones ganados en área propia
                        </label>
                    </div>


                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="centrospermitidos" type="checkbox" value="" v-model="centrospermitidos"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="centrospermitidos"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Centros permitidos
                        </label>
                    </div>


                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="rechaces" type="checkbox" value="" v-model="rechaces"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="rechaces"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Rechaces
                        </label>
                    </div>


                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="barridas" type="checkbox" value="" v-model="barridas"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="barridas"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Barridas
                        </label>
                    </div>

                </div>

                <button type="button" @click="selectAllChecks()"
                    class="mt-6 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    {{allSelected?'Deseleccionar todo':'Seleccionar todo'}} 
                </button>

            </div>
        </div>
    </div>
</template>


<script>

import CanchaCitec from "@/componentes/3d/CanchaCitec.js";
import GolstatsServices from '@/services/GolstatsServices'


export default {
  name: "analissiDefensivo",
  data: () => ({
    golstatsServices: undefined,
    plays:[],
    playsSeparated:{},
    check3D: true,
    balonesRecuperados:false,
    balonesRecuperadosSinDisputa:false,
    unovsunoexitososdefensivos:false,
    unovsunonoexitososdefensivos:false,
    balonesganadosenareapropia:false,
    centrospermitidos:false,
    rechaces:false,
    barridas:false,
    allSelected:false
  }),
  components: {

  },
  props: {
    idPlayer:{
        type: Number,
    },
    idGame:{
        type: Number,
    },
  },

  methods: {

    utilsInitCancha() {
        this.$options.canchaCitec = new CanchaCitec({$options:{}}, "contenedor-canvas", false);
        this.$options.canchaCitec.drawStadium("Cancha-Ok.glb");
       // this.$options.canchaCitec.paintEventLine(0,0, 100,0, 0,100)   
        // this.$options.canchaCitec.createBox(0,0)
        // this.$options.canchaCitec.createBox(0,100)
        // this.$options.canchaCitec.createBox(100,0)
        // this.$options.canchaCitec.createBox(100,100)
        

    },

    async invocaData(){
        let dataAcces = {"mail":"pasaporte@axeleratum.com","pass":"sJJett_DffSv#767466554"}
        await this.golstatsServices.login(dataAcces )
        .then(result=>{
        }).catch(error =>{
            console.log(error);
        })
    },

    async invocaDataJugador(){

        let params = {
        "idPlayer": this.idPlayer,
        "idGame":this.idGame
        }

        await this.golstatsServices.pasesPartidoJugadorSelecciones(params)
        .then(result=>{
            
            this.plays = result.data.data.plays

console.log( this.plays);

            this.plays.map(play =>{
                if(this.playsSeparated[play[4]] == undefined){
                    this.playsSeparated[play[4]] = [] 
                }
                this.playsSeparated[play[4]].push(play)  
            })

        }).catch(error =>{
            console.log(error);
        })
    },

    paintLinesbyCategoryId(idCategory,variable){

        if(this[variable]){
            let plays = this.playsSeparated[idCategory]
            if (plays) {
                plays.map(play =>{
                    this.$options.canchaCitec.paintEventLine(play[12]*100,play[11]*100,play[14]*100,play[13]*100,play[16]*100,play[15]*100,variable)   
                })
            } 
        }else{
            this.$options.canchaCitec.deleteTemporalLinesIdentifier(variable)
        }
    },
    
    moveCameraTo(x1,z1){
        this.$options.canchaCitec.moveCameraTo({x:x1,y:17,z:z1})
    },

    change2D3D(){
        if(this.check3D){
            this.moveCameraTo(15,0)
        }else{
            this.moveCameraTo(0.1,0)
        }
    },

    selectAllChecks(){
        if (this.allSelected) {
            this.balonesRecuperados = false
            this.balonesRecuperadosSinDisputa = false
            this.unovsunoexitososdefensivos = false
            this.unovsunonoexitososdefensivos = false
            this.balonesganadosenareapropia = false
            this.centrospermitidos = false
            this.rechaces = false
            this.barridas = false
            this.allSelected = false
        }else{
            this.balonesRecuperados = true
            this.balonesRecuperadosSinDisputa = true
            this.unovsunoexitososdefensivos = true
            this.unovsunonoexitososdefensivos = true
            this.balonesganadosenareapropia = true
            this.centrospermitidos = true
            this.rechaces = true
            this.barridas= true
            this.allSelected = true
        }
    }

  },
    watch: {
        balonesRecuperados(){
            this.paintLinesbyCategoryId(482,'balonesRecuperados')
        },
        balonesRecuperadosSinDisputa(){
            this.paintLinesbyCategoryId(484,'balonesRecuperadosSinDisputa')
        },
        unovsunoexitososdefensivos(){
            this.paintLinesbyCategoryId(5,'unovsunoexitososdefensivos')
        },
        unovsunonoexitososdefensivos(){
            this.paintLinesbyCategoryId(4,'unovsunonoexitososdefensivos')
        },
        balonesganadosenareapropia(){
            this.paintLinesbyCategoryId(23,'balonesganadosenareapropia')
        },
        centrospermitidos(){
            this.paintLinesbyCategoryId(13-25,'centrospermitidos')
        },
        rechaces(){
            this.paintLinesbyCategoryId(34,'rechaces')
        },
        barridas(){
            this.paintLinesbyCategoryId(518,'barridas')
        }
            
    },

    async mounted() {

        this.golstatsServices = new GolstatsServices(this)

        this.utilsInitCancha()
        await this.invocaData()

        this.invocaDataJugador()
}
}
</script>