<template>
    <div class="flex">
        <div class="px-5 py-2 md:p-5 w-full">
            <div class="bg-paper p-3 rounded-2xl px-5" style="min-height: 20px;">
                <div class="md:flex items-center justify-center">
                    <div id="cancha-container" class=" w-full rounded">
                        <canvas id="contenedor-canvas-3" class="p-0 w-full rounded"></canvas> 
                    </div>
                </div>

                <div class="flex flex-no-wrap bg-paper-light bg-opacity-60 items-center justify-center" >
                    <div class="w-2/5 flex-none p-2">
                        <div class="flex text-gray-700 text-center bg-gray-400 p-2">
                            <div class="flex-initial flex items-end h-5">
                                <input id="goles" type="checkbox" value="" v-model="goles" class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer" required>
                            </div>
                            <label for="goles" class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                                Goles
                            </label>
                        </div>
                    </div>

                    <div class="w-2/5 flex-none p-2">
                        <div class="flex text-gray-700 text-center bg-gray-400 p-2">
                            <div class="flex-initial flex items-end h-5">
                                <input id="poste" type="checkbox" value="" v-model="poste" class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer" required>
                            </div>
                            <label for="poste" class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                                Poste
                            </label>
                        </div>
                    </div>

                    <div class="w-2/5 flex-none p-2">
                        <div class="flex text-gray-700 text-center bg-gray-400 p-2">
                            <div class="flex-initial flex items-end h-5">
                                <input id="poste" type="checkbox" value="" v-model="fuera" class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer" required>
                            </div>
                            <label for="fuera" class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                                Fuera
                            </label>
                        </div>
                    </div>

                    <div class="w-2/5 flex-none p-2">
                        <div class="flex text-gray-700 text-center bg-gray-400 p-2">
                            <div class="flex-initial flex items-end h-5">
                                <input id="poste" type="checkbox" value="" v-model="detenidos" class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer" required>
                            </div>
                            <label for="detenidos" class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                                Parados por el portero
                            </label>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CanchaCitec from "@/componentes/3d/CanchaCitec.js";
import GolstatsServices from '@/services/GolstatsServices'

export default {
  name: "analissiOfensivasGoles",
  data: () => ({
    golstatsServices: undefined,
    plays:[],
    playsSeparated:{},
    check3D: true,
    goles:false,
    poste:false,
    fuera:false,
    detenidos:false,
  }),
  components: {

  },
  props: {
    idPlayer:{
        type: Number,
    },
    idGame:{
        type: Number,
    },
  },

  methods: {

    utilsInitCancha() {
        this.$options.canchaCitec3 = new CanchaCitec({$options:{}}, "contenedor-canvas-3", false,true);
        this.$options.canchaCitec3.drawStadium("Cancha-Ok.glb");
       // this.$options.canchaCitec.paintEventLine(0,0, 100,0, 0,100) 
        
        //this.$options.canchaCitec3.createBoxPorteria(0,0)
        //this.$options.canchaCitec3.createBoxPorteria(0,100)
        //this.$options.canchaCitec3.createBoxPorteria(100,0)
        //this.$options.canchaCitec3.createBoxPorteria(100,100)
        //this.$options.canchaCitec3.createBoxPorteria(50,50)
        

        //0,0
        //this.$options.canchaCitec3.createBoxPorteria(22,0)
        //0,100
         //this.$options.canchaCitec3.createBoxPorteria(22,85)
        //100,0
        //this.$options.canchaCitec3.createBoxPorteria(78,0)   
        //100,100
        //this.$options.canchaCitec3.createBoxPorteria(78,85)        
    },

    async invocaData(){
        let dataAcces = {"mail":"pasaporte@axeleratum.com","pass":"sJJett_DffSv#767466554"}
        await this.golstatsServices.login(dataAcces )
        .then(result=>{
        }).catch(error =>{
            console.log(error);
        })
    },

    async invocaDataJugador(){

        let params = {
        "idPlayer": this.idPlayer,
        "idGame":this.idGame
        }


        console.log(params);
        
        await this.golstatsServices.tirosPartidoJugadorSelecciones(params)
        .then(result=>{
            this.plays = result.data.data.plays
            console.log("this.plays: ", this.plays);
            this.plays.map(play =>{

                if(play[2]== this.idPlayer){ 

                    if(this.playsSeparated[play[4]] == undefined){
                        this.playsSeparated[play[4]] = [] 
                    }

                    this.playsSeparated[play[4]].push(play)  
                }   
            })

        }).catch(error =>{
            console.log(error);
        })
    },

    paintLinesbyCategoryId(idCategory,variable){

        let tipo = 0

        let offsetGolX = .391
        let offsetGolY = 0
        let offsetBx = 22
        let offsetBy = 0 

        let rX = 206
        let rY = 607

        if(this[variable]){

            let plays = this.playsSeparated[idCategory]

            if (plays) {

                plays.map(play =>{

                    if(play[4]==12000){ //Gol
                        tipo=1
                    }else if(play[4]==59800){ //Poste
                        tipo=2
                    }else if(play[4]==53800){ //Fuera
                        tipo=3
                        //play[12] += .97
                    }else if(play[4]==60000){ // Portero
                        tipo=4
                    }

                    let posBx = (play[11] - offsetGolX) * rX
                    let posBallX = posBx+offsetBx

                    let posBy = (play[12] - offsetGolY) * rY
                    let posBallY = posBy+offsetBy

                    if(play[11]<.300){posBallX = 15}

                    this.$options.canchaCitec3.createBoxPorteria(posBallX,posBallY,variable,tipo)  

                })
            }
        }else{
            this.$options.canchaCitec3.deleteTemporalLinesIdentifier(variable)
        }
    },
    
    moveCameraTo(x1,z1){
        this.$options.canchaCitec3.moveCameraTo({x:x1,y:17,z:z1})
    },

    change2D3D(){
        if(this.check3D){
            this.moveCameraTo(15,0)
        }else{
            this.moveCameraTo(0.1,0)
        }
    },

    selectAllChecks(){
        if (this.allSelected) {
            this.goles = false
            this.poste = false
            this.fuera = false
            this.detenidos = false
        }else{
            this.goles = true
            this.poste = true
            this.fuera = true
            this.detenidos = true
        }
    }

  },
    watch: {
        goles(){
            this.paintLinesbyCategoryId(12000,'goles')
        },
        poste(){
            this.paintLinesbyCategoryId(59800,'poste')
        },
        fuera(){
            this.paintLinesbyCategoryId(53800,'fuera')
        },
        detenidos(){
            this.paintLinesbyCategoryId(60000,'detenidos')
        }
    },

    async mounted() {

        this.golstatsServices = new GolstatsServices(this)

        this.utilsInitCancha()
        await this.invocaData()
        this.invocaDataJugador()
    }
}
</script>