
export default class Lines {
  constructor (BABYLON) {
    this.BABYLON = BABYLON
  }

  line2D (name, options, scene) {
    let positions = []
    let positionsObject = []
    let indices = []
    let normals = []
    let width = options.width || 1
    let path = options.path
    let closed = options.closed || false
    let standardUV
    let lineNormal
    let direction

    if (options.standardUV === undefined) {
      standardUV = true
    } else {
      standardUV = options.standardUV
    }

    let outerData = []
    let innerData = []
    let angle = 0
    
    let nbPoints = path.length
    let line = this.BABYLON.Vector3.Zero()
    let nextLine = this.BABYLON.Vector3.Zero()
    path[1].subtractToRef(path[0], line)

    if (nbPoints > 2 && closed) {
      path[2].subtractToRef(path[1], nextLine)
      for (let p = 0; p < nbPoints; p++) {
        angle = Math.PI - Math.acos(this.BABYLON.Vector3.Dot(line, nextLine)/(line.length() * nextLine.length()))            
        direction = this.BABYLON.Vector3.Cross(line, nextLine).normalize().y                
        lineNormal = new this.BABYLON.Vector3(-line.z, 0, 1 * line.x).normalize()
        line.normalize()
        innerData[(p + 1) % nbPoints] = path[(p + 1) % nbPoints]
        outerData[(p + 1) % nbPoints] = path[(p + 1) % nbPoints].add(lineNormal.scale(width)).add(line.scale(direction * width/Math.tan(angle/2)))        
        line = nextLine.clone()
        path[(p + 3) % nbPoints].subtractToRef(path[(p + 2) % nbPoints], nextLine)    
      }
    } else {
      lineNormal = new this.BABYLON.Vector3(-line.z, 0, 1 * line.x).normalize()
      line.normalize()
      innerData[0] = path[0]
      outerData[0] = path[0].add(lineNormal.scale(width))
    
      for (let p = 0; p < nbPoints - 2; p++) {
        path[p + 2].subtractToRef(path[p + 1], nextLine)
        angle = Math.PI - Math.acos(this.BABYLON.Vector3.Dot(line, nextLine)/(line.length() * nextLine.length()))			
        direction = this.BABYLON.Vector3.Cross(line, nextLine).normalize().y			
        lineNormal = new this.BABYLON.Vector3(-line.z, 0, 1 * line.x).normalize()
        line.normalize()
        innerData[p + 1] = path[p + 1]
        outerData[p + 1] = path[p + 1].add(lineNormal.scale(width)).add(line.scale(direction * width/Math.tan(angle/2)))		
        line = nextLine.clone()
      }
      if (nbPoints > 2) {
        path[nbPoints - 1].subtractToRef(path[nbPoints - 2], line)
        lineNormal = new this.BABYLON.Vector3(-line.z, 0, 1 * line.x).normalize()
        line.normalize()
        innerData[nbPoints - 1] = path[nbPoints - 1]
        outerData[nbPoints - 1] = path[nbPoints - 1].add(lineNormal.scale(width))
      } else {
        innerData[1] = path[1]
        outerData[1] = path[1].add(lineNormal.scale(width))
      }
    }
    
    let maxX = Number.MIN_VALUE
    let minX = Number.MAX_VALUE
    let maxZ = Number.MIN_VALUE
    let minZ = Number.MAX_VALUE
    
    for (let p = 0; p < nbPoints; p++) {
      positions.push(innerData[p].x, innerData[p].y, innerData[p].z)
      positionsObject.push({x:innerData[p].x, y:innerData[p].y, z:innerData[p].z})
      maxX = Math.max(innerData[p].x, maxX)
      minX = Math.min(innerData[p].x, minX)
      maxZ = Math.max(innerData[p].z, maxZ)
      minZ = Math.min(innerData[p].z, minZ)
    }

    for (let p = 0; p < nbPoints; p++) {
      positions.push(outerData[p].x, outerData[p].y, outerData[p].z)
      positionsObject.push({x:outerData[p].x, y:outerData[p].y, z:outerData[p].z})
      maxX = Math.max(innerData[p].x, maxX)
      minX = Math.min(innerData[p].x, minX)
      maxZ = Math.max(innerData[p].z, maxZ)
      minZ = Math.min(innerData[p].z, minZ)
    }

    for (let i = 0; i < nbPoints - 1; i++) {
      indices.push(i, i + 1, nbPoints + i + 1)
      indices.push(i, nbPoints + i + 1, nbPoints + i)
    }

    if (nbPoints > 2 && closed) {
      indices.push(nbPoints - 1, 0, nbPoints)
      indices.push(nbPoints - 1, nbPoints, 2 * nbPoints - 1)
    }

    normals = []
    let uvs = []

    if (standardUV) {
      for (let p = 0; p < positions.length; p += 3) {
        uvs.push((positions[p] - minX)/(maxX - minX), (positions[p + 2] - minZ)/(maxZ - minZ))       
      }
    }
    else {
      let flip = 0
      let p1 = 0
      let p2 = 0
      let p3 = 0
      let v0 = innerData[0]
      let v1 = innerData[1].subtract(v0)
      let v2 = outerData[0].subtract(v0)
      let v3 = outerData[1].subtract(v0)
      let axis = v1.clone()
      axis.normalize()

      p1 = this.BABYLON.Vector3.Dot(axis, v1)
      p2 = this.BABYLON.Vector3.Dot(axis, v2)
      p3 = this.BABYLON.Vector3.Dot(axis, v3)
      let minX = Math.min(0, p1, p2, p3)
      let maxX = Math.max(0, p1, p2, p3)
      
      uvs[2 * indices[0]] = -minX/(maxX - minX)
      uvs[2 * indices[0] + 1] = 1
      uvs[2 * indices[5]] = (p2 - minX)/(maxX - minX)
      uvs[2 * indices[5] + 1] = 0
      
      uvs[2 * indices[1]] = (p1 - minX)/(maxX - minX)
      uvs[2 * indices[1] + 1] = 1
      uvs[2 * indices[4]] = (p3 - minX)/(maxX - minX)
      uvs[2 * indices[4] + 1] = 0
    
      for (let i = 6; i < indices.length; i +=6) {
        flip = (flip + 1) % 2
        v0 = innerData[0]
        v1 = innerData[1].subtract(v0)
        v2 = outerData[0].subtract(v0)
        v3 = outerData[1].subtract(v0)
        axis = v1.clone()
        axis.normalize()

        p1 = this.BABYLON.Vector3.Dot(axis, v1)
        p2 = this.BABYLON.Vector3.Dot(axis, v2)
        p3 = this.BABYLON.Vector3.Dot(axis, v3)
        let minX = Math.min(0, p1, p2, p3)
        let maxX = Math.max(0, p1, p2, p3)
      
        uvs[2 * indices[i + 1]] = flip + Math.cos(flip * Math.PI) * (p1 - minX) / (maxX - minX)
        uvs[2 * indices[i + 1] + 1] = 1
        uvs[2 * indices[i + 4]] = flip + Math.cos(flip * Math.PI) * (p3 - minX) / (maxX - minX)
        uvs[2 * indices[i + 4] + 1] = 0
      }
    }
    

    this.BABYLON.VertexData.ComputeNormals(positions, indices, normals)
    this.BABYLON.VertexData._ComputeSides(this.BABYLON.Mesh.DOUBLESIDE, positions, indices, normals, uvs)  	
    
    let customMesh = new this.BABYLON.Mesh('custom', scene)
    let vertexData = new this.BABYLON.VertexData()


    var mat = new this.BABYLON.StandardMaterial("mat", scene);
    mat.diffuseColor = new this.BABYLON.Color3.FromHexString(options.color ||'#ffffff')
    customMesh.material = mat
  
    vertexData.positions = positions
    vertexData.indices = indices
    vertexData.normals = normals
    vertexData.uvs = uvs

    vertexData.applyToMesh(customMesh)

    return customMesh
  }


  getPointsToVector(totalPoints,x1,y1,x2,y2,x3,y3) {
   
    let output = []
    let aumentoSegmento1X = (x2 - x1) / totalPoints
    let aumentoSegmento1Y = (y2 - y1) / totalPoints

    let aumentoSegmento2X = (x3 - x2) / totalPoints
    let aumentoSegmento2Y = (y3 - y2) / totalPoints

    for (let index = 0; index < totalPoints; index++) {
      output.push({ x: x1 += aumentoSegmento1X, y: y1 += aumentoSegmento1Y})
    }

    for (let index = 0; index < totalPoints; index++) {
      output.push({ x: x2 += aumentoSegmento2X, y: y2 += aumentoSegmento2Y })
    }

    return output
  }
}
