<template>
    <div class="grid grid-cols-12 gap-1 ">
        <div class="col-span-8 bg-paper-light px-0 py-0 rounded bg-opacity-60">
            <div class="flex justify-between px-2 py-2">
                <span class="text-base-100">Visualización</span>
                <div class="flex">
                    <label class="inline-flex relative items-center cursor-pointer">
                        <span
                            class="ml-0 mr-2 text-sm font-medium text-base-100 dark:text-gray-300">2D</span>
                    </label>
                    <label for="default-toggle" class="inline-flex relative items-center cursor-pointer">
                        <input type="checkbox" value="" id="default-toggle" class="sr-only peer" @change="change2D3D()" v-model="check3D">
                        <div
                            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-base-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                        </div>
                        <span class="ml-3 text-sm font-medium text-base-100 dark:text-gray-300">3D</span>
                    </label>
                </div>
                <div></div>
            </div>
            <div class="flex w-full py-0">
                <div class="md:flex items-center justify-center">
                    <canvas id="contenedor-canvas-2" class="p-0 w-full rounded"></canvas> 
                </div>
            </div>
        </div>
        <div class="col-span-4 bg-paper-light p-2 rounded bg-opacity-60">
            <div>
                <span class="text-base-100">Categorías</span>

                <div class="flex flex-col divide-y divide-solid divide-gray-200/10">

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="goles" type="checkbox" value="" v-model="goles"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="goles"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Goles
                        </label>
                    </div>

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="asistencias" type="checkbox" value="" v-model="asistencias"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="asistencias"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Asistencias
                        </label>
                    </div>

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="participacion_en_gol" type="checkbox" value="" v-model="participacionengol"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="participacion_en_gol"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Participación en gol
                        </label>
                    </div>

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="tiros_a_gol" type="checkbox" value="" v-model="tirosagol"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="tiros_a_gol"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Tiros a gol
                        </label>
                    </div>


                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="tiros_a_porteria" type="checkbox" value="" v-model="tirosagolporteria"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="tiros_a_porteria"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Tiros a gol (portería)
                        </label>
                    </div>


                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="centros_totales" type="checkbox" value="" v-model="centrostotales"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="centros_totales"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Centros totales
                        </label>
                    </div>


                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="centros_acertados" type="checkbox" value="" v-model="centrosacertados"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="centros_acertados"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Centros acertados
                        </label>
                    </div>


                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="balones_ganados_en_are_rival" type="checkbox" value="" v-model="balonesganadosenarearival"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="balones_ganados_en_are_rival"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            Balones ganados en área rival
                        </label>
                    </div>

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="1vs1exitosos_ofensivos" type="checkbox" value="" v-model="unovsunoexitososofensivos"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="1vs1exitosos_ofensivos"
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            1vs1 exitosos ofensivos
                        </label>
                    </div>

                    <div class="flex items-start my-1">
                        <div class="flex items-end h-5">
                            <input id="1v1_no_exitosos_ofensivos" type="checkbox" value="" v-model="unovsunonoexitososofensivos"
                                class="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 cursor-pointer"
                                required>
                        </div>
                        <label for="1v1_no_exitosos_ofensivos" 
                            class="ml-2 mt-[5.5px] text-xs font-medium text-base-100 dark:text-gray-300 cursor-pointer">
                            1vs1 no exitosos ofensivos
                        </label>
                    </div>

                </div>

                <button type="button" @click="selectAllChecks()"
                    class="mt-6 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    {{allSelected?'Deseleccionar todo':'Seleccionar todo'}} 
                </button>

            </div>
        </div>
    </div>
</template>


<script>

import CanchaCitec from "@/componentes/3d/CanchaCitec.js";
import GolstatsServices from '@/services/GolstatsServices'


export default {
  name: "analissiOfensivas",
  data: () => ({
    golstatsServices: undefined,
    plays:[],
    playsSeparated:{},
    check3D: true,
    goles:false,
    asistencias:false,
    participacionengol:false,
    tirosagol:false,
    tirosagolporteria:false,
    centrostotales:false,
    centrosacertados:false,
    balonesganadosenarearival:false,
    unovsunoexitososofensivos:false,
    unovsunonoexitososofensivos:false,
    allSelected:false
  }),
  components: {

  },
  props: {
    idPlayer:{
        type: Number,
    },
    idGame:{
        type: Number,
    },
  },

  methods: {

    utilsInitCancha() {
        this.$options.canchaCitec2 = new CanchaCitec({$options:{}}, "contenedor-canvas-2", false);
        this.$options.canchaCitec2.drawStadium("Cancha-Ok.glb");
        //this.$options.canchaCitec2.paintEventLine(0,0, 100,0, 0,100)   
        //this.$options.canchaCitec2.createBox(0,0)
        //this.$options.canchaCitec2.createBox(0,100)
        //this.$options.canchaCitec2.createBox(100,0)
        //this.$options.canchaCitec2.createBox(100,100)
        

    },

    async invocaData(){
        let dataAcces = {"mail":"pasaporte@axeleratum.com","pass":"sJJett_DffSv#767466554"}
        await this.golstatsServices.login(dataAcces )
        .then(result=>{
        }).catch(error =>{
            console.log(error);
        })
    },

    async invocaDataJugador(){


        let params = {
        "idPlayer": this.idPlayer,
        "idGame":this.idGame
        }
        console.log("Consulta Jugador");
        await this.golstatsServices.pasesPartidoJugadorSelecciones(params)
        .then(result=>{

            this.plays = result.data.data.plays

            console.log(this.plays);
            
            this.plays.map(play =>{

                if(this.playsSeparated[play[4]] == undefined){
                    this.playsSeparated[play[4]] = [] 
                }

                this.playsSeparated[play[4]].push(play)  

            })

        }).catch(error =>{
            console.log(error);
        })
    },

    paintLinesbyCategoryId(idCategory,variable){

        if(this[variable]){

            let plays = this.playsSeparated[idCategory]

            if (plays) {

                plays.map(play =>{
                    this.$options.canchaCitec2.paintEventLine(play[12]*100,play[11]*100,play[14]*100,play[13]*100,play[16]*100,play[15]*100,variable)   
                })
            }

        }else{
            this.$options.canchaCitec2.deleteTemporalLinesIdentifier(variable)
        }

    },
    
    moveCameraTo(x1,z1){
        this.$options.canchaCitec2.moveCameraTo({x:x1,y:17,z:z1})
    },

    change2D3D(){
        if(this.check3D){
            this.moveCameraTo(15,0)
        }else{
            this.moveCameraTo(0.1,0)
        }
    },

    selectAllChecks(){
        if (this.allSelected) {
            this.goles = false
            this.asistencias = false
            this.participacionengol = false
            this.tirosagol = false
            this.tirosagolporteria = false
            this.centrostotales = false
            this.centrosacertados = false
            this.balonesganadosenarearival = false
            this.unovsunoexitososofensivos = false
            this.unovsunonoexitososofensivos = false
            this.allSelected = false
        }else{
            this.goles = true
            this.asistencias = true
            this.participacionengol = true
            this.tirosagol = true
            this.tirosagolporteria = true
            this.centrostotales = true
            this.centrosacertados = true
            this.balonesganadosenarearival = true
            this.unovsunoexitososofensivos = true
            this.unovsunonoexitososofensivos = true
            this.allSelected = true
        }
    }

  },
    watch: {
        goles(){ 
            this.paintLinesbyCategoryId(12,'goles')
        },
        asistencias(){
            this.paintLinesbyCategoryId(10,'asistencias')
        },
        participacionengol(){
            this.paintLinesbyCategoryId(27,'participacionengol')
        },
        tirosagol(){
            this.paintLinesbyCategoryId(19,'tirosagol')
        },
        tirosagolporteria(){
            this.paintLinesbyCategoryId(91-95,'tirosagolporteria')
        },  
        centrostotales(){
            this.paintLinesbyCategoryId(13-25,'centrostotales')
        },
        centrosacertados(){
            this.paintLinesbyCategoryId(612,'centrosacertados')
        },
        balonesganadosenarearival(){
            this.paintLinesbyCategoryId(11,'balonesganadosenarearival')
        },
        unovsunoexitososofensivos(){
            this.paintLinesbyCategoryId(4,'unovsunoexitososofensivos')
        },
        unovsunonoexitososofensivos(){
            this.paintLinesbyCategoryId(208,'unovsunonoexitososofensivos')
        }
    },

    async mounted() {

        this.golstatsServices = new GolstatsServices(this)

        this.utilsInitCancha()
        await this.invocaData()

        this.invocaDataJugador()
}
}
</script>