import BaseAPI from "./BaseAPI";


export default class WyscoutServices{
  
  constructor (vueInstance) {
    this.apiClient = new BaseAPI(vueInstance)
    this.store = vueInstance.$store
    this.base = process.env.VUE_APP_URL_AZURE_CITEC
  }

  getEstadisticaAvanzadaJugador(params){

    return this.apiClient.post(`https://iad360.citec.futbol/api/CitecManagerServices?service=variablesSelecciones`, params)
    //return this.apiClient.get(`${this.base}?tech=WYSCOUT&externalService=estadisticasAvanzadasJugador&PLAYERID=${idJugador}&COMPID=617`)

  }

  getVideoJugada(idGame,start, end){

    console.log("https://iad360.citec.futbol/api/CitecManagerServices?tech=WYSCOUT&externalService=videosPartido&MATCHID="+idGame+'&START='+start+'&END='+end);
    return this.apiClient.get(`https://iad360.citec.futbol/api/CitecManagerServices?tech=WYSCOUT&externalService=videosPartido&MATCHID=${idGame}&START=${start}&END=${end}`)

  }


  login(data){
    
    return  this.store.dispatch(process.env.VUE_APP_AUTH_REQUEST, data)
  }
}