import { render, staticRenderFns } from "./analisisDefensivo.vue?vue&type=template&id=e13e5868"
import script from "./analisisDefensivo.vue?vue&type=script&lang=js"
export * from "./analisisDefensivo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports