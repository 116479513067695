import decode from 'jwt-decode'
import axios from 'axios'

export default class BaseAPI{
  
  constructor (vueInstance) {
    
    this.domain = process.env.VUE_APP_BASE_URL
    this.fetch = this.fetch.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.getDecodeToken = this.getDecodeToken.bind(this)
    this.setInterceptor = this.setInterceptor.bind(this)
    this.store =  vueInstance.$store


    this.axiosGraphQL = axios.create({
      headers: {
        Authorization: this.store.getters.getToken
      }
    })

    this.axiosInsecure = axios.create({

    })
    
    this.setInterceptor(this.axiosGraphQL,this.store)

  }

  insecure(){

  }

  setInterceptor(apiClient,store){

    apiClient.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      if (error.response.status === 403 ) {
        console.log(error);
        store.dispatch(process.env.VUE_APP_AUTH_LOGOUT)
        router.push('/login')

        
        return Promise.reject(error);
      }

    });

  }

  loggedIn () {
    const token = this.getToken()
    return !!token && !this.isTokenExpired(token)
  }

  get(url,data) {
    return this.axiosGraphQL.get(url, data);
  }

  post (url,data) {
    return this.axiosGraphQL.post(url, data);
  }

  getI(url,data) {
    return this.axiosInsecure.get(url, data);
  }

  postI (url,data) {
    return this.axiosInsecure.post(url, data);
  }

  isTokenExpired (token) {
    try {
      if (token) {
        const decoded = decode(token)
        if (decoded.exp < Date.now() / 1000) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (err) {
      return false
    }
  }

  getToken () {
    return this.store.getters.getToken
  }

  getDecodeToken () {
    if (this.store.getters.getToken != null && this.store.getters.getToken !== undefined) {
      return decode(this.store.getters.getToken)
    } else {
      return false
    }
  }

  getProfile () {
    return this.getDecodeToken().sub
  }

  fetch (url, options) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    if (this.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.getToken()
    }

    return fetch(this.domain + url, {
      headers,
      ...options
    })
      .then(this._checkStatus)
      .then(response => response.json())
  }

  _checkStatus (response) {
    if (response.status >= 200 && response.status < 300) { 
      return response
    } else {
      var error = new Error(response.statusText)
      error.response = response

      throw error
    }
  }
}